import { styled, StyledProps } from '@material-ui/core';

import React from 'react';
import { Container, Grid, Typography, Box } from '@material-ui/core';
import { topics } from 'contents';
import TopicCard from 'components/TopicCard';
import { Paragraph } from 'app/shared';

export default styled((props: StyledProps) => (
  <section className={props.className}>
    <Container maxWidth="lg">
      <Box textAlign="center" my={4}>
        <Typography variant="h2" gutterBottom>
          <b>Learning Modules</b>
        </Typography>
        <Paragraph gutterBottom>
          The Cheshire SEED Program supports early childhood educators to foster children’s social and emotional
          development through their everyday interactions.
        </Paragraph>
      </Box>
      <Grid container spacing={4}>
        {topics.map((topic, index) => (
          <Grid item key={topic.id} xs={12} sm={6} md={4}>
            <TopicCard topic={topic} category={`Module ${index + 1}`} />
          </Grid>
        ))}
      </Grid>
    </Container>
  </section>
))(props => ({
  padding: props.theme.spacing(5, 0, 10, 0),
}));
