import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardMedia, CardContent, Typography, CardActions, ButtonBase } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Topic } from 'contents';
import LinkButton from './LinkButton';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    categoryText: {
      color: theme.palette.grey[500],
    },
    titleText: {
      fontFamily: 'Lora, serif',
      fontSize: 26,
      margin: theme.spacing(2, 0),
    },
  }),
);
export type TopicCardProps = {
  category?: string;
  topic: Topic;
};

export default function TopicCard(props: TopicCardProps) {
  const { topic, category } = props;
  const classes = useStyles();
  const history = useHistory();
  const href = `/topics/${topic.id}/${topic.entry}`;
  return (
    <Card className={classes.root}>
      <ButtonBase onClick={() => history.push(href)}>
        <CardMedia component="img" alt={topic.title} height={250} image={topic.cover} title={topic.title} />
      </ButtonBase>

      <CardContent style={{ flex: 1 }}>
        {category && (
          <Typography className={classes.categoryText} variant="button" gutterBottom>
            {category}
          </Typography>
        )}

        <Typography className={classes.titleText} variant="h5" component="h2" gutterBottom>
          {topic.title}
        </Typography>

        <Typography variant="body1" color="textSecondary" paragraph>
          {topic.description}
        </Typography>
      </CardContent>

      <CardActions>
        <LinkButton to={href} color="primary">
          Start
        </LinkButton>
      </CardActions>
    </Card>
  );
}
