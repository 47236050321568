import { Lesson } from 'contents/types';
import React from 'react';

export default [
  {
    id: 'supporting_play',
    title: 'Supporting Play',
    content: React.lazy(() => import('./l1')),
  },
  {
    id: 'explicit_teaching',
    title: 'Explicit Teaching',
    content: React.lazy(() => import('./l2')),
  },
  {
    id: 'social_stories',
    title: 'Social Stories',
    content: React.lazy(() => import('./l3')),
  },
  {
    id: 'language_to_encourage_behaviour',
    title: 'Language to Encourage Behaviour',
    content: React.lazy(() => import('./l4')),
  },
  {
    id: 'empathic_reflections',
    title: 'Empathic Reflections',
    content: React.lazy(() => import('./l5')),
  },
  {
    id: 'step_ladder',
    title: 'Step Ladder',
    content: React.lazy(() => import('./l6')),
  },
  {
    id: 'short_instructions',
    title: 'Short Instructions',
    content: React.lazy(() => import('./l7')),
  },
  {
    id: 'limit_questions',
    title: 'Limit Questions',
    content: React.lazy(() => import('./l8')),
  },
  {
    id: 'expanding_language',
    title: 'Expanding Language',
    content: React.lazy(() => import('./l9')),
  },
  {
    id: 'book_sharing',
    title: 'Book Sharing',
    content: React.lazy(() => import('./l10')),
  },
  {
    id: 'stage_appropriate_toys',
    title: 'Stage appropriate Toys and Games',
    content: React.lazy(() => import('./l11')),
  },
  {
    id: 'animal_walk',
    title: 'Animal Walk',
    content: React.lazy(() => import('./l12')),
  },
  {
    id: 'movement_breaks',
    title: 'Movement Breaks',
    content: React.lazy(() => import('./l13')),
  },
  {
    id: 'room_resources',
    title: 'Room Resources',
    content: React.lazy(() => import('./l14')),
  },

] as Lesson[];
