import { StepField } from 'components/StepFrom';
import allStrategies from 'contents/strategies';
import { Lesson } from 'contents';

const steps: StepField[] = [
  {
    id: 'name',
    label: 'Please provide a name for this priority (e.g. Priority #1)',
    description: 'Priority',
    type: 'text',
    required: true,
  },
  {
    id: 'descibe_behaviours',
    label: 'Please describe the behaviours observed',
    description: 'Describe behaviour',
    helperText: 'Example: Hitting and kicking other children, seems to become angry quickly, has trouble calming down.',
    type: 'text',
    required: true,
  },
  {
    id: 'behaviour',
    label: 'Please select the category that most closely matches the behaviour',
    type: 'select',
    options: [
      "I'm interested in universal strategies that benefit all children",
      'Anxious or withdrawn',
      'Aggressive',
      'Oppositional',
      'Impulsive and/or hyperactive',
      'Emotionally reactive',
    ],
    required: true,
  },
  {
    id: 'time',
    label: 'Please select the time of day the behaviour is usually observed',
    type: 'select',
    options: [
      'At arrival/drop off',
      'During transitions',
      'During child-led play',
      'During educator-led sessions',
      'Throughout the day (during child-led and educator-led time)',
    ],
    required: true,
  },
  {
    id: 'reason',
    label: 'What do you think is the reason (function) of the behaviour?',
    helperText:
      'Example: Seems to be avoiding coming into the room after free play. May be seeking attention from educators.',
    type: 'text',
    required: true,
  },
  {
    id: 'strategies',
    label: 'What strategies have you tried? Please let us know if these were successful or unsuccessful.',
    helperText:
      'Example: Time in Calming Space, separate child from other children, talking with child about what they are feeling, musical cues to flag upcoming transitions.',
    type: 'text',
    required: true,
  },
  {
    id: 'goal',
    label: 'What is your goal?',
    helperText: 'Example: Reduce aggressive behaviour. Increase prosocial behaviours.',
    type: 'text',
    required: true,
  },
];

export default steps;

export const stepsResultToBehaviours = (behaviourIndex: number, timeIndex: number): Lesson[] => {
  const behaviourTimeStrategies: Array<[number, number[], number[], number[], number[], number[], number[]]> = [
    [0, [0], [0], [0], [0], [0], [0]],
    [1, [0], [0], [0], [0], [0], [0]],
    [2, [], [0], [0], [0], [0], [0]],
    [3, [], [0], [], [], [], [0]],
    [4, [1], [1], [1], [1], [1], [1]],
    [5, [], [1, 2, 3], [1, 2, 3], [1, 2, 3], [1, 2, 3], [1, 2, 3]],
    [6, [], [], [], [1, 2, 3], [1, 2, 3], []],
    [7, [2, 3], [2, 3], [2, 3], [2, 3], [2, 3], [2, 3]],
    [8, [2, 3], [2, 3], [2, 3], [2, 3], [2, 3], [2, 3]],
    [9, [], [3], [3], [3], [3], [3]],
    [10, [1, 2, 3], [], [1, 2, 3], [1, 2, 3], [1, 2, 3], [1, 2, 3]],
    [11, [3], [3], [3], [3], [3], [3]],
    [12, [3], [3], [3], [3], [3], [3]],
    [13, [], [3], [3], [3], [3], [3]],
    [13, [], [], [], [], [], []],
    [14, [], [], [2, 3], [2, 3], [], []],
    [15, [3], [], [2, 3], [2, 3], [], []],
  ];
  return behaviourTimeStrategies
    .filter(row => {
      const behaviourTimes = row.slice(1) as number[][];
      const times = behaviourTimes[behaviourIndex];
      return times && times.indexOf(timeIndex) >= 0;
    })
    .map(row => {
      const strategyIndex = row[0];
      return allStrategies[strategyIndex];
    });
};
