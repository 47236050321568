import React from 'react';
import { Topic } from 'contents/types';
import red from '@material-ui/core/colors/red';

export default {
  id: 'foundations',
  entry: 'overview',
  title: '1. Program Foundation',
  description:
    'Learn about the SEED program, its foundations at The Cheshire School and how to move through the modules.',
  cover: '/assets/m1/cover.jpg',
  themeColor: red[300],
  lessons: [
    {
      id: 'overview',
      title: '1.0 Overview and Learning Outcomes',
      content: React.lazy(() => import('./l0')),
    },
    {
      id: 'how_does_it_work',
      title: '1.1 How Does it Work',
      content: React.lazy(() => import('./l1')),
    },
    {
      id: 'toolkit_concept',
      title: '1.2 The Cheshire SEED Educational Program Concepts',
      content: React.lazy(() => import('./l2')),
    },
    {
      id: 'have_your_say',
      title: '1.3 Have Your Say',
      content: React.lazy(() => import('./l3')),
    },
  ],
} as Topic;
