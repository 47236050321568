import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardHeader, CardContent, TextField, Box } from '@material-ui/core';
import { Form, mustBeEmail, notEmpty } from 'components/form';
import Button from 'components/ActionStatusButton';
import Link from 'components/Link';
import AuthPage from './AuthPage';

import { Credential } from 'app/types';
import { selectAuth } from 'app/store';
import { signIn } from './authSlice';

type SigninData = Credential & { rememberMe: boolean };

const initialData: SigninData = {
  email: '',
  password: '',
  rememberMe: true,
};

const validators = {
  email: mustBeEmail(),
  password: notEmpty(),
};

export default function Signin() {
  const { status } = useSelector(selectAuth);
  const dispatch = useDispatch();

  const handleSubmit = (data: SigninData) => {
    dispatch(signIn(data));
  };

  return (
    <AuthPage>
      <Card>
        <CardHeader title="Signin" />
        <CardContent>
          <Form<SigninData> data={initialData} validators={validators} onSubmit={handleSubmit}>
            {({ values, errors, updateField }) => (
              <Box>
                <TextField
                  variant="outlined"
                  name="email"
                  label="Email"
                  value={values.email}
                  onChange={e => updateField('email', e.target.value)}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  type="password"
                  variant="outlined"
                  name="password"
                  label="Password"
                  value={values.password}
                  onChange={e => updateField('password', e.target.value)}
                  error={Boolean(errors.password)}
                  helperText={errors.password}
                  margin="normal"
                  fullWidth
                />
                <Box my={2}>
                  <Button type="submit" variant="contained" color="primary" status={status} fullWidth>
                    Signin
                  </Button>
                </Box>
                <Box>
                  <Link to="/auth/resetPassword">Forgot Password?</Link>
                </Box>
              </Box>
            )}
          </Form>
        </CardContent>
      </Card>
    </AuthPage>
  );
}
