import React from 'react';
import {
  Lesson,
  topics,
  environments,
  strategies,
  foundationalstrategies,
  interactions,
  anxietyStrategies,
  autismStrategies,
} from 'contents';

const specialLessons: Record<string, Lesson[]> = {
  environments,
  strategies,
  foundationalstrategies,
  interactions,
  anxietyStrategies,
  autismStrategies,
};

export default function useLessonAndLinks(topicId: string, lessonId: string) {
  return React.useMemo(() => {
    const topic = topics.find(topic => topic.id === topicId);
    const lessons = topic ? topic.lessons : specialLessons[topicId];
    const lessonIndex = lessons?.findIndex(lesson => lesson.id === lessonId);
    if (lessonIndex >= 0) {
      const lesson = lessons[lessonIndex];
      const prevLesson = lessonIndex > 0 && lessons[lessonIndex - 1];
      const nextLesson = lessonIndex < lessons.length - 1 && lessons[lessonIndex + 1];
      return {
        lesson,
        prevLink: prevLesson ? `/topics/${topicId}/${prevLesson.id}` : null,
        nextLink: nextLesson ? `/topics/${topicId}/${nextLesson.id}` : null,
      };
    } else {
      return {};
    }
  }, [topicId, lessonId]);
}
