import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, IconButton, CircularProgress, Menu, MenuItem, Divider, ListSubheader } from '@material-ui/core';
import AccountIcon from '@material-ui/icons/AccountCircle';
import LinkButton from 'components/LinkButton';
import { selectAuth } from 'app/store';
import { signOut } from 'app/auth/authSlice';

export default function AppBarActions() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const { authenticated, userInfo } = useSelector(selectAuth);

  if (authenticated === null) {
    return <CircularProgress variant="indeterminate" size={18} color="secondary" />;
  }

  if (!authenticated) {
    return (
      <LinkButton to="/auth/signin" color="inherit">
        Signin
      </LinkButton>
    );
  }

  const handleSignout = () => {
    setAnchorEl(null);
    dispatch(signOut(() => history.replace('/')));
  };

  return (
    <Box>
      <IconButton onClick={e => setAnchorEl(e.currentTarget)} color="inherit">
        <AccountIcon />
      </IconButton>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <ListSubheader>{userInfo?.displayName} </ListSubheader>
        <Divider />
        <MenuItem onClick={() => handleSignout()}>Signout</MenuItem>
      </Menu>
    </Box>
  );
}
