import React from 'react';
import { Topic } from 'contents/types';
import teal from '@material-ui/core/colors/teal';

export default {
  title: '5. My Learning',
  entry: 'overview',
  id: 'my_learning',
  description: 'Extend your learning and share your experiences with other educators.',
  cover: '/assets/m5/cover.jpg',
  themeColor: teal[300],
  lessons: [
    {
      id: 'overview',
      title: '5.0 Overview and Learning Outcomes',
      content: React.lazy(() => import('./l0')),
    },
    {
      id: 'webinar',
      title: '5.1 Current Webinar',
      content: React.lazy(() => import('./l1')),
    },
    {
      id: 'recordings',
      title: '5.2 Webinar recordings',
      content: React.lazy(() => import('./l2')),
    },
  ],
} as Topic;
