import React from 'react';
import { get, isEmpty } from 'lodash';
import { Stepper, Step, StepLabel, StepContent, Typography, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import StepQuestion from './StepQuestion';
import { StepField, StepFormData } from './types';

export * from './types';

const useStyles = makeStyles(theme =>
  createStyles({
    field: {
      margin: theme.spacing(2, 0),
    },

    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }),
);

export type StepFormProps = {
  data: StepFormData;
  steps: StepField[];
  onChange: (data: StepFormData) => void;
};

export default function StepForm(props: StepFormProps) {
  const { steps, data, onChange } = props;
  const classes = useStyles();

  const handleNext = () => {
    const nextStep = Math.min(steps.length, data.activeStep + 1);
    onChange({ ...data, activeStep: nextStep });
  };

  const handleBack = () => {
    const nextStep = Math.max(0, data.activeStep - 1);
    onChange({ ...data, activeStep: nextStep });
  };

  const updateField = (id: string, value: unknown) => {
    const nextValues = { ...data.values, [id]: value };
    onChange({ ...data, values: nextValues });
  };

  const { activeStep, values } = data;
  return (
    <Stepper style={{ borderRadius: 12 }} activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => {
        const value = get(values, step.id);
        const blockNext = Boolean(step.required) && isEmpty(value);
        return (
          <Step key={step.id}>
            <StepLabel>
              <Typography variant="subtitle1">{step.label}</Typography>
              {index !== activeStep && (
                <Typography color="textSecondary" variant="body1">
                  {value}
                </Typography>
              )}
            </StepLabel>

            <StepContent>
              <Typography variant="body2">{step.description}</Typography>
              <div className={classes.field}>
                <StepQuestion
                  id={step.id}
                  variant={step.type}
                  options={step.options}
                  value={value}
                  onChange={value => {
                    updateField(step.id, value);
                  }}
                  helperText={step.helperText}
                />
                <div>
                  <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                    Back
                  </Button>
                  <Button
                    disabled={blockNext}
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        );
      })}
    </Stepper>
  );
}
