import { set, clone } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { app } from 'firebaseApp';
import { AppThunk, Profile } from 'app/store';
import { ActionStatus, request, idle, failure } from 'app/helper';
import { AuthenticatedPayload } from 'app/auth/authSlice';

export type ProfileState = {
  root: Profile | null;
  status: ActionStatus | null;
};

export const initialState: ProfileState = {
  root: null,
  status: null,
};

const { actions, reducer } = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateLesson(state, action: PayloadAction<UpdateLessonInput>) {
      const { lessonKey, contentId, content } = action.payload;
      let lessons = clone(state.root?.lessons || {});
      lessons = set(lessons, [lessonKey, contentId], content);
      state.root = {
        ...state.root,
        lessons,
      } as Profile;
    },

    request: request('status'),
    idle: idle('status'),
    failure: failure('status'),
  },
  extraReducers: {
    authenticated(state, action: PayloadAction<AuthenticatedPayload>) {
      state.root = action.payload.profile;
      state.status = null;
    },

    notAuthenticated() {
      return initialState;
    },
  },
});

export default reducer;

export { actions };

type UpdateLessonInput = {
  lessonKey: string;
  contentId: string;
  content: any;
};

export const updateLesson = (input: UpdateLessonInput): AppThunk => async (dispatch, state) => {
  dispatch(actions.request());
  try {
    dispatch(actions.updateLesson(input));
    const { lessonKey, contentId, content } = input;
    const uid = state().auth.userInfo?.uid;
    if (!uid) {
      throw new Error('Not uid');
    }
    await app
      .firestore()
      .collection('profiles')
      .doc(uid)
      .update({
        [`lessons.${lessonKey}.${contentId}`]: content,
      });
    dispatch(actions.idle());
  } catch (err) {
    dispatch(actions.failure(err.message || 'failed to update lesson'));
  }
};
