import React from 'react';
import { Lesson } from 'contents/types';

export default [
  {
    id: 'talking_with_children',
    title: 'Talking with Children Who Are Feeling Anxious',
    content: React.lazy(() => import('./talking_with_children')),
  },
  {
    id: 'reframing_thoughts',
    title: 'Reframing Negative Thoughts',
    content: React.lazy(() => import('./reframing_thoughts')),
  },
  {
    id: 'graded_exposure',
    title: 'Graded Exposure',
    content: React.lazy(() => import('./graded_exposure')),
  },
  {
    id: 'good_goodbye',
    title: 'Good Goodbye',
    content: React.lazy(() => import('./good_goodbye')),
  },
  {
    id: 'doing_the_turtle',
    title: 'Doing the Turtle',
    content: React.lazy(() => import('./doing_the_turtle')),
  },
  {
    id: 'visual_supports',
    title: 'Visual Supports',
    content: React.lazy(() => import('./visual_supports')),
  },
  {
    id: 'personalise_externalise',
    title: 'Personalise and Externalise',
    content: React.lazy(() => import('./personalise_externalise')),
  },
  {
    id: 'happy_dolphines',
    title: 'Happy Dolphins, Part 2',
    content: React.lazy(() => import('./happy_dolphines')),
  },
] as Lesson[];
