import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Chip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import environments from 'contents/environments';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      ...theme.typography.body1,
      width: '100%',
      color: 'inherit',
      borderColor: 'rgba(255,255,255,.7)',
    },
  }),
);

export type EnvironmentsGridProp = {
  environmentIndexes: number[];
};

export function EnvironmentsGrid(props: EnvironmentsGridProp) {
  const classes = useStyles(props);
  const { environmentIndexes } = props;
  const { topicId, lessonId } = useParams<{ topicId: string; lessonId: string }>();
  const history = useHistory();
  return (
    <Grid container spacing={2}>
      {environmentIndexes.map(index => {
        const lesson = environments[index];
        return (
          <Grid item key={index} xs={12} md={6}>
            <Chip
              className={classes.chip}
              variant="outlined"
              label={environments[index].title}
              onClick={() => history.push(`/topics/environments/${lesson.id}`, { breadcrumb: { topicId, lessonId } })}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
