import React from 'react';
import StrategyWizardBase from './StrategyWizard';
import useContent from '../useContent';
import { isEmpty } from 'lodash';
import { StepFormData } from 'components/StepFrom';
import { Box, Button } from '@material-ui/core';

export * from './Behaviours';

export const StrategyWizard = () => {
  const [content, setContent] = useContent('priorities');
  const priorities: Array<any> = isEmpty(content) ? [{}] : content;

  const setData = (index: number, data: StepFormData) => {
    const nextPriorities = priorities.map((content, i) =>
      i === index ? { ...data.values, activeStep: data.activeStep } : content,
    );
    setContent(nextPriorities);
  };

  const handleAdd = () => {
    const nextPriorities = [...priorities, { activeStep: 0 }];
    setContent(nextPriorities);
  };

  const handleRemove = (index: number) => () => {
    const nextPriorities = priorities.filter((content, i) => i !== index);
    setContent(nextPriorities);
  };

  return (
    <>
      {priorities.map((content, i) => {
        const { activeStep = 0, ...values } = content || {};
        const data = { activeStep, values: values || {} };
        return (
          <StrategyWizardBase
            key={i}
            data={data}
            setData={data => setData(i, data)}
            onRemove={i > 0 ? handleRemove(i) : undefined}
          />
        );
      })}
      <Box textAlign="center">
        <Button onClick={handleAdd} variant="contained" fullWidth={false} color="primary">
          Create another priority
        </Button>
      </Box>
    </>
  );
};
