import { configureStore, getDefaultMiddleware, Action } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { ThunkAction } from 'redux-thunk';

export * from './types';

export const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware({ serializableCheck: false })],
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export const selectAuth = (state: RootState) => state.auth;
export const selectProfile = (state: RootState) => state.profile;
export const selectVideo = (state: RootState) => state.video;
