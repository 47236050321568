import React from 'react';
import Video from 'components/Video';
import YoutubeIcon from '@material-ui/icons/YouTube';
import { VideoEvent } from 'app/types';
import { StyledProps, styled } from '@material-ui/core';
import ExtLink from 'components/ExtLink';

export default (props: { video: VideoEvent }) => {
  const { video } = props;
  return <Video id={video.videoId} title={<Caption video={video} />} />;
};

const Caption = styled((props: StyledProps & { video: VideoEvent }) => {
  const { video, className } = props;
  return (
    <div className={className}>
      <span>{video.description}</span>
      <ExtLink href={video.link} color="secondary" className="link">
        <YoutubeIcon /> <span>Open in YouTube</span>
      </ExtLink>
    </div>
  );
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '0.5rem',
  color: theme.palette.text.secondary,
  ...theme.typography.caption,
  '& > .link': {
    marginTop: '0.5rem',
    display: 'flex',
    alignItems: 'center',
  },
}));
