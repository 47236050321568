import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Container, Typography } from '@material-ui/core';
import Alert from 'components/Alert';

import { selectAuth } from 'app/store';
import { ContinueState } from 'app/withAuth';
import { actions } from './authSlice';
import Logo from 'components/Logo';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.dark,
      cursor: 'pointer',
    },

    logo: {
      marginRight: theme.spacing(1),
    },
  }),
);

export type AuthPageProps = Pick<React.HTMLProps<HTMLElement>, 'children'>;

export default function AuthPage(props: AuthPageProps) {
  const { children } = props;
  const { authenticated, status } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation<ContinueState>();
  const history = useHistory();

  if (authenticated) {
    const continueUrl = location.state?.continueUrl;
    return <Redirect to={continueUrl || '/'} />;
  }

  return (
    <div className={classes.root}>
      <Alert alertText={status?.error} onClose={() => dispatch(actions.idle())} variant="filled" severity="error" />
      <Box py={6}>
        <header className={classes.header} onClick={() => history.push('/')}>
          <Logo size="large" className={classes.logo} />
          <Typography variant="h2">
            Cheshire <strong>SEED</strong>
          </Typography>
        </header>
      </Box>
      {children && <Container maxWidth="xs">{children}</Container>}
    </div>
  );
}
