import React from 'react';
import { get } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Typography, IconButton, Button, Grid, Chip } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import RemoveIcon from '@material-ui/icons/Delete';
import StepForm, { StepFormData } from 'components/StepFrom';
import { Lesson } from 'contents/types';
import { Card } from '../Card';
import steps, { stepsResultToBehaviours } from './steps';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      margin: theme.spacing(4, 0),
    },

    title: {
      flex: 1,
    },

    paper: {
      margin: theme.spacing(2, 0),
    },

    chip: {
      ...theme.typography.body1,
      width: '100%',
    },
  }),
);

const behaviourOptions = steps[2].options!;

const timeOptions = steps[3].options!;

export type StrategyWizardProps = {
  onRemove?: () => void;
  data: StepFormData;
  setData: (data: StepFormData) => void;
};

export default function StrategyWizard(props: StrategyWizardProps) {
  const { onRemove, data, setData } = props;
  const classes = useStyles();
  const [lessons, setLessons] = React.useState<Lesson[] | null>(null);
  const { topicId, lessonId } = useParams<{ topicId: string; lessonId: string }>();
  const history = useHistory();

  React.useEffect(() => {
    if (data.activeStep >= steps.length) {
      updateLessons(data.values);
    }
    // eslint-disable-next-line
  }, []);

  const updateLessons = (values: object) => {
    const behaviourIndex = behaviourOptions.indexOf(get(values, 'behaviour'));
    const timeIndex = timeOptions.indexOf(get(values, 'time'));
    const lessons = stepsResultToBehaviours(behaviourIndex, timeIndex);
    setLessons(lessons);
  };

  const handleChange = (nextData: StepFormData) => {
    setData(nextData);
    if (nextData.activeStep >= steps.length) {
      updateLessons(nextData.values);
    }
  };

  const handleRestart = () => {
    setLessons(null);
    setData({ ...data, activeStep: 0 });
  };

  const name = get(data.values, 'name', `Priority`);
  const completed = lessons !== null;

  return (
    <Card outline>
      <Box mb={2} display="flex" alignItems="center">
        <h3 className={classes.title}>{name}</h3>
        {onRemove && (
          <IconButton onClick={onRemove}>
            <RemoveIcon />
          </IconButton>
        )}
      </Box>

      <StepForm data={data} steps={steps} onChange={handleChange} />
      {completed && (
        <Box mt={4}>
          <Typography variant="h6">Strategy List</Typography>
          <Grid className={classes.paper} container spacing={2}>
            {lessons!.map(lesson => (
              <Grid key={lesson.id} item xs={12} md={6}>
                <Chip
                  color="primary"
                  className={classes.chip}
                  label={lesson.title}
                  onClick={() => history.push(`/topics/strategies/${lesson.id}`, { breadcrumb: { topicId, lessonId } })}
                />
              </Grid>
            ))}
          </Grid>

          <Box mt={4}>
            <Button color="primary" fullWidth onClick={handleRestart}>
              Retry
            </Button>
          </Box>
        </Box>
      )}
    </Card>
  );
}
