import React from 'react';
import { RouteComponentProps, Redirect, useHistory } from 'react-router-dom';
import { Container, ListItem, Typography, List, ListItemText, Divider, Paper, Box } from '@material-ui/core';
import {
  environments,
  strategies,
  foundationalstrategies,
  interactions,
  anxietyStrategies,
  autismStrategies,
  Lesson,
} from 'contents';

import { AppPage } from 'app/shared';

const topics: Record<string, Lesson[]> = {
  environments,
  strategies,
  foundationalstrategies,
  interactions,
  anxietyStrategies,
  autismStrategies,
};

export type TopicProps = RouteComponentProps<{ topicId: string }>;

export default function Topic(props: TopicProps) {
  const { topicId } = props.match.params;
  const history = useHistory();
  const lessons = topics[topicId] as Lesson[];
  if (!lessons) {
    return <Redirect to="/404" />;
  }

  return (
    <AppPage title={topicId} responsive fixed gutter>
      <Container maxWidth="md">
        <Typography variant="h3">{topicId}</Typography>
        <Box my={4}>
          <Paper>
            <List>
              {lessons.map(lesson => (
                <React.Fragment key={lesson.id}>
                  <ListItem button onClick={() => history.push(`/topics/${topicId}/${lesson.id}`)}>
                    <ListItemText primary={lesson.title} />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Box>
      </Container>
    </AppPage>
  );
}
