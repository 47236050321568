import React from 'react';
import { FormData } from 'components/form';
import useContent from '../useContent';
import Form, { FormProps } from './Form';

type ContentFormProps = {
  id: string;
} & Omit<FormProps, 'onSubmit' | 'data'>;

export default function ContentForm(props: ContentFormProps) {
  const { id, ...formProps } = props;
  const [content, setContent] = useContent(id);
  const handleSubmit = (data: FormData) => {
    setContent(data);
  };

  return <Form {...formProps} onSubmit={handleSubmit} data={(content as FormData) || null} />;
}
