import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { styled, StyledProps } from '@material-ui/core/styles';
import { themeColors } from 'app/theme';

export default styled((props: StyledProps) => (
  <section className={props.className}>
    <Container maxWidth="md">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6} className="scene">
          <img src="images/landing/social.png" alt="eat" />
        </Grid>
        <Grid item xs={12} md={6}>
          <blockquote>
            "Social and emotional development is the first thing that we are going to try and develop for the children." - Early Childhood Educator 
          </blockquote>
        </Grid>
      </Grid>
    </Container>
  </section>
))(props => ({
  backgroundColor: themeColors.honeydrew,
  color: props.theme.palette.primary.dark,
  padding: props.theme.spacing(8, 0),
  '& .scene': {
    '& > img': {
      display: 'block',
      width: 400,
      margin: '0 auto',
    },
  },

  '& blockquote': {
    position: 'relative',
    fontFamily: 'Lora, serif',
    fontSize: 32,
    lineHeight: 1.3,
    fontStyle: 'italic',
    margin: props.theme.spacing(2),
    color: 'inherits',
  },
}));
