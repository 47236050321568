import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/analytics';

const config = {
  apiKey: 'AIzaSyAsITC4EjKtkvp_fXP2UShmNYt-t5yOQNM',
  authDomain: 'cheshire-toolkit.firebaseapp.com',
  databaseURL: 'https://cheshire-toolkit.firebaseio.com',
  projectId: 'cheshire-toolkit',
  storageBucket: 'cheshire-toolkit.appspot.com',
  messagingSenderId: '624924343533',
  appId: '1:624924343533:web:f25f1234ba2c2e62ade0e4',
  measurementId: 'G-2VLE85FE7Z',
};

export const app = firebase.initializeApp(config);
