import React from 'react';
import { TextField, Typography, Box, Button } from '@material-ui/core';
import { Form, RadioGroup } from 'components/form';
import { Card } from '../Card';
import Field from './Field';

export type FeedbackData = {
  helpful: 'Yes' | 'No' | null;
  comment?: string;
};

const initialData = {
  helpful: null,
  comment: '',
};

export type FeedbackFormProps = {
  data: FeedbackData | null;
  onSubmit: (data: FeedbackData) => void;
};

export default function FeedbackForm(props: FeedbackFormProps) {
  const { data, onSubmit } = props;
  const [completed, setCompleted] = React.useState(data !== null);

  React.useEffect(() => {
    if (data !== null) {
      setCompleted(true);
    }
  }, [data]);

  const canSubmit = (values: FeedbackData) => {
    if (values.helpful === null) {
      return false;
    }
    return true;
  };

  return (
    <Card title="Feedback" subheader="Please let us know what you think" outline>
      {completed ? (
        <>
          <Field label="Was this helpful?">
            <Typography>{data?.helpful}</Typography>
          </Field>

          {data?.comment && (
            <Field label="Can you tell us why you didn’t find this helpful? Is there anything more we could add?">
              <Typography>{data.comment}</Typography>
            </Field>
          )}

          <Box mt={4}>
            <Button onClick={() => setCompleted(false)} fullWidth>
              Change feedback
            </Button>
          </Box>
        </>
      ) : (
        <Form<FeedbackData> data={data || initialData} validators={{}} onSubmit={onSubmit}>
          {({ values, errors, updateField }) => (
            <>
              <Field label="Was this helpful?">
                <RadioGroup
                  options={['Yes', 'No']}
                  value={values.helpful}
                  onChange={e => {
                    const value = e.target.value;
                    updateField('helpful', value);
                  }}
                />
              </Field>
              {values.helpful === 'No' && (
                <Field label="Can you tell us why you didn’t find this helpful? Is there anything more we could add?">
                  <TextField
                    variant="outlined"
                    label="Comment"
                    value={values.comment || ''}
                    rows={3}
                    onChange={e => updateField('comment', e.target.value)}
                    fullWidth
                    multiline
                  />
                </Field>
              )}

              <Box mt={4}>
                <Button variant="contained" color="primary" type="submit" disabled={!canSubmit(values)} fullWidth>
                  Submit
                </Button>
              </Box>
            </>
          )}
        </Form>
      )}
    </Card>
  );
}
