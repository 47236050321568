import React from 'react';
import { Topic } from 'contents/types';
import lightGreen from '@material-ui/core/colors/lightGreen';

export default {
  id: 'making_play_therapeutic',
  entry: 'overview',
  title: '6. Making Play Therapeutic',
  description: 'We outline strategies to maximise the therapeutic benefits of play.',
  cover: '/assets/m6/module6_cover.jpg',
  themeColor: lightGreen[300],
  lessons: [
    {
      id: 'overview',
      title: '6.0 Overview and Learning Outcomes',
      content: React.lazy(() => import('./l0')),
    },
    {
      id: 'meaning_of_play',
      title: '6.1 The Meaning of Play',
      content: React.lazy(() => import('./l1')),
    },
    {
      id: 'creative_play',
      title: '6.2 Creative Play',
      content: React.lazy(() => import('./l2')),
    },
    {
      id: 'look_at_me',
      title: '6.3 Look at me!’ Play',
      content: React.lazy(() => import('./l3')),
    },
    {
      id: 'good_vs_bad',
      title: '6.4 Themes of Good Versus Bad',
      content: React.lazy(() => import('./l4')),
    },
    {
      id: 'themes_of_control',
      title: '6.5 Themes of Control',
      content: React.lazy(() => import('./l5')),
    },
    {
      id: 'physical_play',
      title: '6.6 Physical Play',
      content: React.lazy(() => import('./l6')),
    },
    {
      id: 'culture_and_play',
      title: '6.7 Culture and Play',
      content: React.lazy(() => import('./l7')),
    },
    {
      id: 'inclusiveness_and_play',
      title: '6.8 Inclusiveness and Play',
      content: React.lazy(() => import('./l8')),
    },
  ],
} as Topic;
