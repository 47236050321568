import { createMuiTheme } from '@material-ui/core/styles';

export const themeColors = {
  imperialRed: '#E63946',
  honeydrew: '#F1FAEE',
  celadonBlue: '#457B9D',
  powerBlue: '#A8DADC',
  prussianBlue: '#1D3557',
};

export default createMuiTheme({
  palette: {
    primary: {
      main: themeColors.celadonBlue,
      light: themeColors.powerBlue,
      dark: themeColors.prussianBlue,
    },
    secondary: {
      main: themeColors.imperialRed,
    },
  },
});
