import { createSlice, PayloadAction, Store, createAction } from '@reduxjs/toolkit';
import { ActionStatus, request, failure, idle } from 'app/helper';
import firebase from 'firebase/app';
import { app } from 'firebaseApp';
import { AppThunk, RootState } from 'app/store';
import { Credential, ActivateInput, Profile } from 'app/types';
import { pick } from 'lodash';

const Auth = firebase.auth.Auth;
type UserInfo = firebase.UserInfo;

export type AuthenticatedPayload = { userInfo: UserInfo; profile: Profile };

export const authenticated = createAction<AuthenticatedPayload>('authenticated');

export const notAuthenticated = createAction('notAuthenticated');

export type AuthState = {
  authenticated: boolean | null;
  userInfo: UserInfo | null;
  status: ActionStatus | null;
};

export const initialState: AuthState = {
  authenticated: null,
  userInfo: null,
  status: null,
};

const { reducer, actions } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    request: request('status'),
    failure: failure('status'),
    idle: idle('status'),
  },
  extraReducers: {
    authenticated(state, action: PayloadAction<AuthenticatedPayload>) {
      const { userInfo } = action.payload;
      state.authenticated = true;
      state.userInfo = userInfo;
      state.status = null;
    },

    notAuthenticated(state) {
      state.authenticated = false;
      state.userInfo = null;
      state.status = null;
    },
  },
});

export default reducer;

export { actions };

/**
 * Sign out
 *
 * @param option
 */
export const signOut = (success?: () => void): AppThunk => async dispatch => {
  await app.auth().signOut();
  dispatch(notAuthenticated());
  success && success();
};

/**
 * Sign in
 *
 * @param data
 * @param option
 */
export const signIn = (data: Credential & { rememberMe?: boolean }): AppThunk => async dispatch => {
  dispatch(actions.request());
  try {
    const { email, password, rememberMe } = data;
    const persistence = rememberMe ? Auth.Persistence.LOCAL : Auth.Persistence.SESSION;
    await app.auth().setPersistence(persistence);
    await app.auth().signInWithEmailAndPassword(email, password);
    dispatch(actions.idle());
  } catch (err) {
    dispatch(actions.failure(err.message || 'Failed to signin'));
  }
};

export const activate = (data: ActivateInput): AppThunk => async dispatch => {
  dispatch(actions.request());
  try {
    const { token, email, password } = data;
    const activateAccount = app.functions().httpsCallable('activateAccount');
    await activateAccount({ token, email, password });
    await app.auth().signInWithEmailAndPassword(email, password);
    dispatch(actions.idle());
  } catch (err) {
    dispatch(actions.failure(err.message || 'Failed to activate your account'));
  }
};

export const sendPasswordResetEmail = (email: string, success: () => void): AppThunk => async dispatch => {
  dispatch(actions.request());
  try {
    const continueUrl = 'http://localhost:3000/auth/signin';
    await app.auth().sendPasswordResetEmail(email, { url: continueUrl });
    dispatch(actions.idle());
    success();
  } catch (err) {
    dispatch(actions.failure(err.message || 'Failed to send password reset email'));
  }
};

/**
 * Watch auth status
 * @param store
 */
export function watchAuth(store: Store<RootState>) {
  return app.auth().onAuthStateChanged(firebaseUser => {
    if (firebaseUser) {
      (async () => {
        const userInfo = pick(firebaseUser, ['displayName', 'email', 'phoneNumber', 'photoURL', 'providerId', 'uid']);
        const profileDoc = await app
          .firestore()
          .collection('profiles')
          .doc(userInfo.uid)
          .get();

        const profile = profileDoc.data() as Profile;
        store.dispatch(authenticated({ userInfo, profile }));
      })();
    } else {
      if (store.getState().auth.authenticated !== false) {
        store.dispatch(notAuthenticated());
      }
    }
  });
}
