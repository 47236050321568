import React from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import { AppPage } from 'app/shared';

export default function Research() {
  return (
    <AppPage title="Research" responsive={false} fixed gutter>
      <Box my={4}>
        <Container maxWidth="md">
          <Typography variant="h2" gutterBottom>
           Articles Published in Peer-Reviewed Academic Journals
          </Typography>
          <Typography paragraph>
            	Blewitt, C., Fuller-Tyszkiewicz, M., Nolan, A., Bergmeier, H., Vicary, D., Huang, T., McCabe, P., McKay, T., & Skouteris, H. (2018). Social and     emotional learning associated with universal curriculum-based interventions in early childhood education and care centers: A systematic review and meta-analysis. JAMA Network Open, 1(8), e185727-e185727. doi:10.1001/jamanetworkopen.2018.5727
	</Typography>
	<Typography paragraph>
		Blewitt, C., Morris, H., Jackson, K., Barrett, H., Bergmeier, H., O’Connor, A., Mousa, A., Nolan, A., & Skouteris, H. (2020). Integrating Health and Educational Perspectives to Promote 		Preschoolers’ Social and Emotional Learning: Development of a Multi-Faceted Program Using an Intervention Mapping Approach. International Journal of Environmental Research and Public Health, 		17(2), 575. doi: 10.3390/ijerph17020575
        </Typography>
        <Typography paragraph>
            	Blewitt, C., Morris, H., Nolan, A., Jackson, K., Barrett, H., & Skouteris, H. (2018). Strengthening the quality of educator-child interactions in early childhood education and care settings: a 		conceptual model to improve mental health outcomes for preschoolers. Early Child Development and Care. doi: 10.1080/03004430.2018.1507028
          </Typography>
	<Typography paragraph>
          	Blewitt, C., O’Connor, A., May, T., Morris, H., Mousa, A., Bergmeier, H., Jackson, K., Barrett, H., & Skouteris, H. (2019). Strengthening the social and emotional skills of pre-schoolers with 			mental 	health and developmental challenges in inclusive early childhood education and care settings: a narrative review of educator-led interventions. Early Child Development and Care. doi: 				10.1080/03004430.2019.1704283
     	</Typography>
          <Typography paragraph>
            	Blewitt, C., O’Connor, A., Morris, H., May, T., Mousa, A., Bergmeier, H., Nolan, A., Jackson, K., Barrett. H., & Skouteris, H. (2019). A systematic review of targeted social and emotional learning interventions in early childhood education and care settings. Early Child Development and Care. doi: 10.1080/03004430.2019.1702037
        </Typography>
	<Typography paragraph>
          	Blewitt, C., O’Connor, A., Morris, H., Mousa, A., Bergmeier, H., Nolan, A., Jackson, K., Barrett, H, & Skouteris, H. (2020). Do Curriculum-Based Social and Emotional Learning Programs in Early 		Childhood Education and Care Strengthen Teacher Outcomes? A Systematic Literature Review. International Journal of Environmental Research and Public Health, 17(3), 1049. doi: 					doi.org/10.3390/ijerph17031049
	</Typography>
        <Typography paragraph>
            	O’Connor, A., Blewitt, C., Nolan, A., & Skouteris, H. (2018). Using Intervention Mapping for child development and wellbeing programs in early childhood education and care settings. Evaluation and Program Planning, 68, 57-63. doi: 10.1016/j.evalprogplan.2018.02.011
          </Typography>
        </Container>
      </Box>
    </AppPage>
  );
}
