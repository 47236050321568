import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardHeader, CardContent, TextField, Box } from '@material-ui/core';
import { Form, notEmpty, mustBeEmail, matchField, passwordPolicy } from 'components/form';
import Button from 'components/ActionStatusButton';
import AuthPage from './AuthPage';

import { ActivateInput } from 'app/types';
import { selectAuth } from 'app/store';
import { activate } from './authSlice';

type ActivateFormData = ActivateInput & {
  confirmPassword: string;
};

const initialData: ActivateFormData = {
  token: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const validators = {
  email: mustBeEmail(),
  token: notEmpty(),
  password: passwordPolicy(),
  confirmPassword: matchField('password'),
};

export default function Activate() {
  const { status } = useSelector(selectAuth);
  const dispatch = useDispatch();

  const handleSubmit = (data: ActivateFormData) => {
    dispatch(activate(data));
  };

  return (
    <AuthPage>
      <Card>
        <CardHeader
          title="Activate your account"
          subheader="Please use the token you have received to create an account."
        />
        <CardContent>
          <Form<ActivateFormData> data={initialData} validators={validators} onSubmit={handleSubmit}>
            {({ values, errors, updateField }) => (
              <>
                <TextField
                  variant="outlined"
                  name="token"
                  label="Token"
                  value={values.token}
                  error={Boolean(errors.token)}
                  helperText={errors.token || "The activation token you've received."}
                  onChange={e => updateField('token', e.target.value)}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  variant="outlined"
                  name="email"
                  label="Your email"
                  value={values.email}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  onChange={e => updateField('email', e.target.value)}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  type="password"
                  variant="outlined"
                  name="password"
                  label="Password"
                  value={values.password}
                  error={Boolean(errors.password)}
                  helperText={errors.password}
                  onChange={e => updateField('password', e.target.value)}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  type="password"
                  variant="outlined"
                  name="confirmPassword"
                  label="Confirm your password"
                  value={values.confirmPassword}
                  error={Boolean(errors.confirmPassword)}
                  helperText={errors.confirmPassword}
                  onChange={e => updateField('confirmPassword', e.target.value)}
                  margin="normal"
                  fullWidth
                />
                <Box my={2}>
                  <Button type="submit" variant="contained" color="primary" status={status} fullWidth>
                    Activate my account
                  </Button>
                </Box>
              </>
            )}
          </Form>
        </CardContent>
      </Card>
    </AuthPage>
  );
}
