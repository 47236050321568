import { FieldValidator, FormData } from './types';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import inRange from 'lodash/inRange';
import isEmail from 'validator/lib/isEmail';
import isByteLength from 'validator/lib/isByteLength';
import isPostalCode from 'validator/lib/isPostalCode';
import moment from 'moment';

export const conditional = (cond: (data: any) => boolean, validator: FieldValidator): FieldValidator => {
  return (field: string, data: FormData) => {
    return cond(data) ? validator(field, data) : null;
  };
};

export const passwordPolicy = (): FieldValidator => {
  return (field: string, data: FormData) => {
    const value = data[field];
    if (isEmpty(value)) {
      return 'Password can not be empty!';
    }
    return /(?=.*\d)(?=.*[a-z]).{8,}/.test(value)
      ? null
      : 'Password must has at least 8 letters, and it contains at least one number and one lower case letter.';
  };
};

export const notEmpty = (): FieldValidator => {
  return (field: string, data: FormData) => {
    return isEmpty(data[field]) ? `${field} can not be empty!` : null;
  };
};

export const mustBetween = (opts: { from: number; to: number; allowEmpty?: boolean }) => (
  field: string,
  data: FormData,
) => {
  const value = data[field];
  if (isNaN(value)) {
    return opts.allowEmpty ? null : `${field} is not a number`;
  }
  return !inRange(value as number, opts.from, opts.to) ? `${field} must between ${opts.from} to ${opts.to}` : null;
};

export function mustBeEmail(): FieldValidator {
  return (field: string, data: FormData) => {
    const value = data[field];
    return isEmpty(value) || !isEmail(value as string) ? `${value} is not a valid email address` : null;
  };
}

export function matchField(targetField: string): FieldValidator {
  return (field: string, data: FormData) => {
    const value = data[field];
    return !isEqual(value, data[targetField]) ? `value didn't match ${targetField}` : null;
  };
}

export function withinLength(min?: number, max?: number): FieldValidator {
  return (field: string, data: FormData) => {
    const value = data[field];
    return isEmpty(value) || !isByteLength(value as string, { min, max })
      ? `${field} must be ${min} - ${max} characters`
      : null;
  };
}

export function mustBeDate(opts: { format?: string; message?: string; allowEmpty?: boolean }): FieldValidator {
  const { format = 'DD/MM/YYYY', message = 'Invalid date format', allowEmpty = false } = opts;
  return (field: string, data: FormData) => {
    const value = data[field];
    if (isEmpty(value)) {
      return allowEmpty ? null : `${field} can not be empty`;
    }
    return !moment(value as string, format, true).isValid() ? message : null;
  };
}

export function mustBePostcode(locale: any) {
  return (field: string, data: FormData) => {
    const value = data[field];
    if (isEmpty(value)) {
      return `${field} can not be empty`;
    }
    return !isPostalCode(value as string, locale) ? 'Invalid postcode' : null;
  };
}

export function createValidator<T>(filedValidators: { [key in keyof T]: FieldValidator }) {
  return {
    validate(data: FormData) {
      return Object.entries(filedValidators).reduce((errors, entry) => {
        const field = entry[0];
        const fieldValidator = entry[1] as FieldValidator;
        const error = fieldValidator(field, data);
        return error ? { ...errors, [field]: error } : errors;
      }, {});
    },
    validateField(data: FormData, field: keyof T) {
      const fieldValidator = filedValidators[field];
      fieldValidator(field as string, data);
    },
  };
}
