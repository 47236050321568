import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Breadcrumbs, Link as MuiLink } from '@material-ui/core';
import Link from 'components/Link';
import { Lesson, topics, environments, strategies } from 'contents';
import { Breadcrumb } from './LessonPage';

export type BreadcrumbNavProps = {
  label: string;
  breadcrumb: Breadcrumb;
};

export default function BreadcrumbNav(props: BreadcrumbNavProps) {
  const { label, breadcrumb } = props;
  return (
    <Box my={2}>
      <Breadcrumbs>
        <LessonLink {...breadcrumb} />
        <div>{label}</div>
      </Breadcrumbs>
    </Box>
  );
}

const specialLessons: Record<string, Lesson[]> = { environments, strategies };

const LessonLink = (props: { topicId: string; lessonId: string }) => {
  const { topicId, lessonId } = props;
  const hisotry = useHistory();
  const lesson = (() => {
    const topic = topics.find(topic => topic.id === topicId);
    const lessons = topic ? topic.lessons : specialLessons[topicId];
    if (lessons) {
      return lessons.find(lesson => lesson.id === lessonId);
    }
  })();

  return lesson ? (
    <Link role="breadcrumb" to={`/topics/${topicId}/${lessonId}`}>
      {lesson.title}
    </Link>
  ) : (
    <MuiLink
      role="breadcrumb"
      href="#"
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        hisotry.goBack();
      }}>
      Go back
    </MuiLink>
  );
};
