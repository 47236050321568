import React from 'react';
import { Topic } from 'contents/types';
import purple from '@material-ui/core/colors/purple';

export default {
  id: 'my_priorities',
  title: '2. My Priorities',
  entry: 'overview',
  description:
    'Identify the strengths and challenges for children in your room, and the factors that might be influencing their behaviour.',
  cover: '/assets/m2/cover.jpg',
  themeColor: purple[300],
  lessons: [
    {
      id: 'overview',
      title: '2.0 Overview and Learning Outcomes',
      content: React.lazy(() => import('./l0')),
    },
    {
      id: 'social_emotional_development',
      title: '2.1 Social and Emotional Development from Birth to Five',
      content: React.lazy(() => import('./l1')),
    },
    {
      id: 'form_and_function_of_behaviour',
      title: '2.2 Form and Function of Behaviour',
      content: React.lazy(() => import('./l2')),
    },
    {
      id: 'factors_influence_behaviour',
      title: '2.3 Factors that Influence Children’s Development and Behaviour',
      content: React.lazy(() => import('./l3')),
    },
    {
      id: 'priorities_1',
      title: '2.4 Tailor My Plan for the Room',
      content: React.lazy(() => import('./l4')),
    },
    {
      id: 'priorities_2',
      title: '2.5 Tailor My Plan for Children',
      content: React.lazy(() => import('./l5')),
    },
    {
      id: 'have_your_say',
      title: '2.6 Have Your Say',
      content: React.lazy(() => import('./l6')),
    },
  ],
} as Topic;
