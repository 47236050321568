import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectProfile } from 'app/store';
import { updateLesson } from 'app/profile/profileSlice';

export default function useContent(contentId: string): [any, (data: any) => void] {
  const { root: profile } = useSelector(selectProfile);
  const dispatch = useDispatch();
  const { topicId, lessonId } = useParams<{ topicId: string; lessonId: string }>();
  const lessonKey = [topicId, lessonId].join('#');

  const content = React.useMemo(() => {
    const lessonState = profile?.lessons[lessonKey];
    return lessonState ? lessonState[contentId] : undefined;
  }, [contentId, lessonKey, profile]);

  const setContent = (content: any) => {
    dispatch(updateLesson({ lessonKey, contentId, content }));
  };

  return [content, setContent];
}
