import React from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import ExtLink from 'components/ExtLink';
import { AppPage } from 'app/shared';
import { Article, P } from 'components/lesson';

export default function Journey() {
  return (
    <AppPage title="Journey" responsive={false} fixed gutter>
      <Box my={4}>
        <Container maxWidth="md">
          <Article>
            <Typography variant="h2">Our Journey</Typography>

            <P>
              The Cheshire School, a program of bestchance Child Family Care, has successfully worked with pre-primary
              and primary school-aged children with significant social, emotional and behavioural challenges for over 30
              years. The School utilises evidence-based techniques that build positive behaviours and resilient mental
              health in young children, with research indicating significant improvement in Cheshire student behaviour,
              attention, anxiety and stress between enrolment and graduation (based on both teacher and parent
              measures). Qualitative research suggests the program also leads to improved quality of life and wellbeing
              for families of Cheshire students.
            </P>

            <P>
              <ExtLink href="https://www.bestchance.org.au/">bestchance</ExtLink> and{' '}
              <ExtLink href="https://www.monash.edu/medicine/sphpm/mchri">Monash University</ExtLink> have adopted a
              systems approach to co-design, implement, and evaluate The Cheshire SEED Educational Model. This program
              seeks to translate the simple but effective therapeutic and positive behaviour strategies from The
              Cheshire School into the early childhood environment, where prevention and early intervention offers
              greatest benefit. Cheshire SEED is based on over two years of research by bestchance and Monash
              University, and the evidence-based strategies used by the school. It has been co-designed with Cheshire
              educators, early childhood educators, child psychologists, play therapists, allied health professionals,
              researchers and parents, and aims to build on educators’ existing skill and expertise to foster children’s
              social and emotional skills and positive mental health in the preschool classroom.
            </P>

            <P>
              We hope Cheshire SEED will be relevant and beneficial to educators over time. The program will be
              continually refined and adapted based on evaluation, educator need and feedback. Our team will be
              developing additional strategies, room resources and targeted modules each year, and educators will be
              able to connect with each other and the Cheshire team to share experiences, and learn from each other
              through Communities of Practice and regular webinars.
            </P>
          </Article>
        </Container>
      </Box>
    </AppPage>
  );
}
