import React from 'react';
import { stepsResultToBehaviours } from './steps';
import { Box, Grid, Chip, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme =>
  createStyles({
    behaviourHeading: {
      display: 'flex',
      alignItems: 'center',
      color: 'rgba(255,255,255,.7)',
      '& > img': {
        display: 'block',
        width: 100,
        marginRight: theme.spacing(2),
      },
    },

    paper: {
      padding: theme.spacing(2),
      backgroundColor: 'rgba(255,255,255, 0.1)',
      color: '#FFF',
    },

    chip: {
      ...theme.typography.body1,
      width: '100%',
      color: 'inherit',
      borderColor: 'rgba(255,255,255,.7)',
    },
  }),
);

const behaviours = [
  {
    title: 'All Children',
    avatar: '/assets/avatars/neutral.png',
  },
  {
    title: 'Anxious Behaviours',
    avatar: '/assets/avatars/anxious.png',
  },
  {
    title: 'Aggressive Behaviours',
    avatar: '/assets/avatars/aggressive.png',
  },
  {
    title: 'Oppositional Behaviours',
    avatar: '/assets/avatars/oppositional.png',
  },
  {
    title: 'Hyperactive/Impulsive Behaviours',
    avatar: '/assets/avatars/hyperactive.png',
  },
  {
    title: 'Emotionally Reactive',
    avatar: '/assets/avatars/emotionally.png',
  },
];

export type BehavioursCardProps = {
  timeIndex: number;
};

export function Behaviours(props: BehavioursCardProps) {
  const { timeIndex } = props;
  const classes = useStyles();
  const history = useHistory();
  const { topicId, lessonId } = useParams<{ topicId: string; lessonId: string }>();

  const handleClick = (id: string) => () => {
    history.push(`/topics/strategies/${id}`, { breadcrumb: { topicId: topicId, lessonId: lessonId } });
  };
  return (
    <>
      {behaviours.map((behaviour, behaviourIndex) => {
        const { title, avatar } = behaviour;
        const lessons = stepsResultToBehaviours(behaviourIndex, timeIndex);
        return (
          <Box p={2} key={behaviourIndex}>
            <div className={classes.behaviourHeading}>
              <img src={avatar} alt={title} />
              <Typography variant="h6" color="inherit">
                {title}
              </Typography>
            </div>
            <Paper className={classes.paper} elevation={0}>
              <Grid container spacing={2}>
                {lessons.map((lesson, i) => (
                  <Grid key={i} item xs={12} md={6}>
                    <Chip
                      onClick={handleClick(lesson.id)}
                      label={lesson.title}
                      className={classes.chip}
                      color="primary"
                      variant="outlined"
                    />
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Box>
        );
      })}
    </>
  );
}
