import React from 'react';
import { Box, Button, Divider } from '@material-ui/core';
import PrevIcon from '@material-ui/icons/ChevronLeft';
import NextIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router';

export const FooterNav = (props: { prevLink?: string | null; nextLink?: string | null }) => {
  const { prevLink, nextLink } = props;
  const history = useHistory();
  return (
    <>
      <Divider />
      <Box mt={2} mb={8} display="flex" justifyContent="space-between">
        <Button color="primary" disabled={!prevLink} onClick={() => history.push(prevLink!)}>
          <PrevIcon /> Previous
        </Button>
        <Button color="primary" disabled={!nextLink} onClick={() => history.push(nextLink!)}>
          Next <NextIcon />
        </Button>
      </Box>
    </>
  );
};
