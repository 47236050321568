import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export type FlipCardProps = {
  title: string;
  cover?: string;
  bg?: string;
  color?: string;
  children: React.ReactNode;
};

type StyleProps = Pick<FlipCardProps, 'bg' | 'color'> & { flip: boolean };

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'block',
      position: 'relative',
      perspective: 800,
      cursor: 'pointer',
    },
    article: (props: StyleProps) => ({
      position: 'relative',
      width: '100%',
      height: '100%',
      minHeight: 200,
      transformStyle: 'preserve-3d',
      transition: 'transform 0.5s',
      transform: props.flip ? 'rotateY(180deg)' : 'rotateY(0deg)',
    }),

    card: {
      borderRadius: 8,
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 0,
      width: '100%',
      height: '100%',

      backfaceVisibility: 'hidden',
    },
    cardFront: (props: StyleProps) => ({
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: props.bg || theme.palette.primary.main,
      color: props.color || theme.palette.primary.contrastText,
      transform: 'rotateY(0deg)',
      '& > img': {
        width: '160px !important',
        margin: theme.spacing(0),
      },
    }),
    cardBack: {
      backgroundColor: '#f0f0f0',
      transform: 'rotateY(180deg)',
    },
    content: {
      padding: theme.spacing(2),
    },
  }),
);

export function FlipCard(props: FlipCardProps) {
  const { title, cover, children, bg, color } = props;
  const [flip, setFlip] = React.useState<boolean>(false);
  const classes = useStyles({ flip, bg, color });
  return (
    <div className={classes.root}>
      <article onClick={() => setFlip(!flip)} className={classes.article}>
        <figure className={[classes.card, classes.cardFront].join(' ')}>
          {cover && <img src={cover} alt="cover" />}
          <Typography variant="h6">{title}</Typography>
        </figure>

        <figure className={[classes.card, classes.cardBack].join(' ')}>
          <Typography variant="caption" align="center" className={classes.content}>
            {children}
          </Typography>
        </figure>
      </article>
    </div>
  );
}
