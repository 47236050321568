import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import AppRoutes from './AppRoutes';
import { store } from './store';
import { watchAuth } from './auth/authSlice';

export * from './types';

export default function App() {
  React.useEffect(() => watchAuth(store), []);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Provider store={store}>
          <AppRoutes />
        </Provider>
      </Router>
    </ThemeProvider>
  );
}
