import React from 'react';
import { ButtonBase, createStyles, makeStyles } from '@material-ui/core';

const width = 200;
const height = 200;

const useStyles = makeStyles(theme =>
  createStyles<string, { src: string }>({
    buttonBase: {
      position: 'relative',
      width: width,
      height: height,
      borderRadius: '50%',
      overflow: 'hidden',
      '&:hover, &$focusVisible': {
        zIndex: 1,
        '& $imageBackdrop': {
          opacity: 0.4,
        },
        '& $label': {
          opacity: 1,
        },
      },
    },

    image: ({ src }) => ({
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundPosition: 'center 40%',
      backgroundImage: `url(${src})`,
    }),

    imageBackdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.4,
      transition: theme.transitions.create('opacity'),
    },

    label: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgba(255,255,255,.9)',
      opacity: 1,
      ...theme.typography.h6,
    },
  }),
);

export type FactorContent = {
  title: string;
  cover: string;
  content: React.ReactNode;
};

export type FactorPopProps = {
  src: string;
  label: string;
  children: React.ReactNode;
  onClick: (content: FactorContent) => void;
};

export function FactorPop(props: FactorPopProps) {
  const { src, label, onClick, children } = props;
  const classes = useStyles({ src });

  return (
    <ButtonBase
      className={classes.buttonBase}
      onClick={() => onClick({ title: label, cover: src, content: children })}
      focusRipple>
      <span className={classes.image} />
      <span className={classes.imageBackdrop} />
      <span className={classes.label}>{label}</span>
    </ButtonBase>
  );
}
