import React from 'react';
import { RouteComponentProps, Redirect, useLocation } from 'react-router-dom';
import BreadcrumbNav from './BreadcrumbNav';
import useLessonAndLinks from './useLessonAndLinks';
import { FooterNav } from './FooterNav';
import { LessonContent } from './LessonContent';

export type Breadcrumb = {
  topicId: string;
  lessonId: string;
};

export type LessonPageProps = RouteComponentProps<{ topicId: string; lessonId: string }, any, {}>;

export function LessonPage(props: LessonPageProps) {
  const { match } = props;
  const { topicId, lessonId } = match.params;
  const location = useLocation<{ breadcrumb: Breadcrumb }>();
  const { lesson, nextLink, prevLink } = useLessonAndLinks(topicId, lessonId);
  const breadcrumb = location.state?.breadcrumb;

  if (!lesson) {
    return <Redirect to="/404" />;
  }

  return (
    <LessonContent
      lessonKey={`${topicId}#${lessonId}`}
      breadcrumbNav={breadcrumb && <BreadcrumbNav label={lesson.title} breadcrumb={breadcrumb} />}
      footNav={!breadcrumb && <FooterNav prevLink={prevLink} nextLink={nextLink} />}
      lesson={lesson}
    />
  );
}
