import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { LessonPage, StrategyPage } from './Lesson';
import Topic from './Topic';

export default function TopicRoutes() {
  return (
    <Switch>
      <Route exact path="/topics/:topicId" component={Topic} />
      <Route exact path="/topics/:topicId/:lessonId" component={LessonPage} />
      <Route exact path="/topics/:topicId/strategies/:strategyId" component={StrategyPage} />
    </Switch>
  );
}
