import React from 'react';
import { isEmpty } from 'lodash';
import {
  List,
  Dialog,
  DialogTitle,
  Box,
  DialogActions,
  Button,
  ListItem,
  ListItemIcon,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
} from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/Schedule';
import YoutubeIcon from '@material-ui/icons/YouTube';
import StatusView from 'components/StatusView';
import VideoPlayer from './VideoPlayer';
import useVideos from './useVideos';
import { VideoEvent, VideoEventStatus } from 'app/types';

export type VideoListProps = {
  videoStatus: VideoEventStatus;
};

export default function VideoList(props: VideoListProps) {
  const { videoStatus } = props;
  const { videos, status } = useVideos(videoStatus);
  const [video, setVideo] = React.useState<VideoEvent | null>(null);

  if (isEmpty(videos)) {
    return (
      <Box p={4}>
        <StatusView label={status ? 'Loading...' : 'No videos'} />
      </Box>
    );
  }

  return (
    <>
      <List>
        {videos!.map(item => (
          <VideoItem key={item.id} video={item} onClick={() => setVideo(item)} />
        ))}
      </List>
      <Dialog open={video !== null} onClose={() => setVideo(null)}>
        {video && (
          <>
            <DialogTitle>{video.title}</DialogTitle>
            <Box minWidth={560}>
              <VideoPlayer video={video} />
            </Box>
            <DialogActions>
              <Button onClick={() => setVideo(null)}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}

type VideoItemProps = {
  video: VideoEvent;
  onClick: (e: React.MouseEvent) => void;
};

export const VideoItem = (props: VideoItemProps) => {
  const { video, onClick } = props;
  const showTime = video.status === 'upcoming';
  return (
    <ListItem onClick={onClick} button dense>
      <ListItemIcon>
        <Avatar src={video.thumbnailUrl} variant="rounded" />
      </ListItemIcon>
      <ListItemText primary={video.title} secondary={video.description} />
      <ListItemSecondaryAction>
        {showTime && <Chip label="17/07/2020 17:00" avatar={<CalendarIcon />} size="small" />}
        {video.status === 'active' && <Chip label="Broadcasting" avatar={<YoutubeIcon />} size="small" />}
      </ListItemSecondaryAction>
    </ListItem>
  );
};
