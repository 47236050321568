import React from 'react';
import { Tabs, Tab, Divider } from '@material-ui/core';
import VideoIcon from '@material-ui/icons/Tv';
import DashboardCard from 'components/DashboardCard';
import VideoList from './VideoList';
import { VideoEventStatus } from 'app/types';

export default function VideosCard() {
  const [videoStatus, setVideoStatus] = React.useState<VideoEventStatus>('active');
  return (
    <DashboardCard avatar={<VideoIcon />} title="Video channel">
      <Tabs
        value={videoStatus}
        onChange={(e, value) => {
          setVideoStatus(value);
        }}>
        <Tab value="active" label="Live" />
        <Tab value="upcoming" label="Upcoming" />
        <Tab value="completed" label="Past Webinars" />
      </Tabs>
      <Divider />
      <VideoList videoStatus={videoStatus} />
    </DashboardCard>
  );
}
