import React from 'react';
import NavPage, { ToggleDrawerButton } from 'components/NavPage';
import AppBar from 'components/AppBar';
import { AppDrawer } from './AppDrawer';
import AppBarActions from './AppBarActions';

export type ContentPageProps = {
  title: string;
  children: React.ReactNode;
};

export function ContentPage(props: ContentPageProps) {
  const { title, children } = props;

  return (
    <NavPage drawerContent={<AppDrawer />} responsive>
      <AppBar menu={<ToggleDrawerButton />} actions={<AppBarActions />} color="primary" title={title} responsive />
      {children}
    </NavPage>
  );
}
