import React from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ContentPage } from 'app/shared';
import { selectProfile } from 'app/store';
import { updateLesson } from 'app/profile/profileSlice';
import { Lesson } from 'contents';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    },
    main: {
      flex: 1,
    },
    gutter: {
      ...theme.mixins.toolbar,
    },
  }),
);

export type LessonContentProps = {
  lessonKey: string;
  breadcrumbNav?: React.ReactNode;
  footNav?: React.ReactNode;
  lesson: Lesson;
};

export function LessonContent(props: LessonContentProps) {
  const { lessonKey, lesson, footNav, breadcrumbNav } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { root: profile } = useSelector(selectProfile);
  const contentElm = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const lessonState = get(profile?.lessons, lessonKey);
    if (!lessonState) {
      dispatch(updateLesson({ lessonKey, contentId: '_visited', content: true }));
    }
  }, [profile, lessonKey, dispatch]);

  React.useEffect(() => {
    if (contentElm.current) {
      contentElm.current.scroll(0, 0);
    }
  }, [lessonKey]);

  return (
    <ContentPage title={lesson.title}>
      <div className={classes.content} ref={contentElm}>
        <main className={classes.main}>
          <div className={classes.gutter} />
          <Container maxWidth="md">
            <React.Suspense fallback={<div>Loading...</div>}>
              {breadcrumbNav}
              <lesson.content />
              {footNav}
            </React.Suspense>
          </Container>
        </main>
      </div>
    </ContentPage>
  );
}
