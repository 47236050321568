import React from 'react';
import { Button, Typography, Box, TextField } from '@material-ui/core';
import { Card } from '../Card';
import Field from './Field';

export type FormData = Record<string, string>;

export type Field = {
  id: string;
  desc?: string;
  type: 'textarea';
  label: string;
  rows: number;
  required?: boolean;
};

export type FormProps = {
  title: string;
  subheader?: React.ReactNode;
  submitLabel?: string;
  fields: Field[];
  data: FormData | null;
  onSubmit: (data: FormData) => void;
  children?: React.ReactNode;
};

export default function Form(props: FormProps) {
  const { title, subheader, submitLabel, fields, children, onSubmit } = props;
  const [data, setData] = React.useState<FormData>(props.data || {});
  const [completed, setCompleted] = React.useState<boolean>(props.data !== null);

  React.useEffect(() => {
    if (props.data !== null && !completed) {
      setCompleted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setCompleted(true);
    onSubmit(data);
  };

  const handleChange = (id: string, value: any) => {
    setData({
      ...data,
      [id]: value,
    });
  };

  return (
    <Card title={title} subheader={subheader} bg="white" outline>
      {completed ? (
        <>
          {fields.map(field => (
            <Field key={field.id} label={field.desc}>
              <Typography>{data[field.id]}</Typography>
            </Field>
          ))}

          {children}

          <Box mt={4}>
            <Button color="primary" onClick={() => setCompleted(false)} fullWidth>
              Retry
            </Button>
          </Box>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          {fields.map(field => (
            <Field key={field.id} label={field.desc}>
              <TextField
                multiline
                variant="outlined"
                label={field.label}
                rows={field.rows}
                required={field.required}
                value={(data[field.id] || '') as string}
                onChange={e => {
                  handleChange(field.id, e.target.value);
                }}
                fullWidth
              />
            </Field>
          ))}
          <Box mt={4}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              {submitLabel || 'Submit'}
            </Button>
          </Box>
        </form>
      )}
    </Card>
  );
}
