import React from 'react';
import { useHistory } from 'react-router-dom';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';

export type LinkProps = MuiButtonProps & {
  to: string;
};

export default function LinkButton(props: LinkProps) {
  const { to, ...linkProps } = props;
  const history = useHistory();
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    history.push(to);
  };
  return <MuiButton href={to} onClick={handleClick} {...linkProps} />;
}
