import React from 'react';
import { Topic } from 'contents/types';
import amber from '@material-ui/core/colors/amber';

export default {
  id: 'talking_with_caregivers',
  entry: 'overview',
  title: '7. Talking with Caregivers',
  description: 'Build confidence in discussing developmental concerns with parents and caregivers and review issues relating to child safety.',
  cover: '/assets/m7/m7_cover.jpg',
  themeColor: amber[300],
  lessons: [
    {
      id: 'overview',
      title: '7.0 Overview and Learning Outcomes',
      content: React.lazy(() => import('./l0')),
    },
    {
      id: 'raising_concerns',
      title: '7.1 Raising Concerns with Caregivers',
      content: React.lazy(() => import('./l1')),
    },
    {
      id: 'plan_meeting',
      title: '7.2 Plan for the Meeting',
      content: React.lazy(() => import('./l2')),
    },
    {
      id: 'during_meeting',
      title: '7.3 During the Meeting',
      content: React.lazy(() => import('./l3')),
    },
    {
      id: 'family_responses',
      title: '7.4 Family Responses',
      content: React.lazy(() => import('./l4')),
    },
    {
      id: 'child_safety',
      title: '7.5 Child Safety',
      content: React.lazy(() => import('./l5')),
    },
  ],
} as Topic;
