import React from 'react';
import { RouteComponentProps, Redirect, useLocation } from 'react-router-dom';
import { autismStrategies, Lesson } from 'contents';
import { LessonContent } from './LessonContent';
import { Breadcrumb } from '.';
import BreadcrumbNav from './BreadcrumbNav';

const allStrategies: Record<string, { strategyId: string; contents: Lesson[] }> = {
  autism: {
    strategyId: 'strategies',
    contents: autismStrategies,
  },
};

export type StrategyPageProps = RouteComponentProps<{ topicId: string; strategyId: string }, any, {}>;

export function StrategyPage(props: StrategyPageProps) {
  const { match } = props;
  const { topicId, strategyId } = match.params;
  const lesson = useContent(topicId, strategyId);
  const location = useLocation<{ breadcrumb: Breadcrumb }>();
  const breadcrumb = location.state?.breadcrumb;

  if (!lesson) {
    return <Redirect to="/404" />;
  }

  return (
    <LessonContent
      lessonKey={`${topicId}#${strategyId}`}
      lesson={lesson}
      breadcrumbNav={breadcrumb && <BreadcrumbNav label={lesson.title} breadcrumb={breadcrumb} />}
    />
  );
}

const useContent = (topicId: string, strategyId: string): Lesson | undefined | void => {
  return React.useMemo(() => {
    const strategy = allStrategies[topicId];
    if (!strategy) {
      return;
    }
    return strategy.contents.find(lesson => lesson.id === strategyId);
  }, [topicId, strategyId]);
};
