import React from 'react';
import { Topic } from 'contents/types';
import blue from '@material-ui/core/colors/blue';

export default {
  id: 'supporting_additional_needs',
  entry: 'overview',
  title: '8. Supporting Children with Additional Needs',
  description: 'This module is designed for educators who are caring for, educating and supporting children with additional needs.',
  cover: '/assets/m8/module8_cover.jpg',
  themeColor: blue[300],
  lessons: [
    {
      id: 'overview',
      title: '8.0 Overview and Learning Outcomes',
      content: React.lazy(() => import('./l0')),
    },
    {
      id: 'foundational_strategies',
      title: '8.1 Foundational Strategies',
      content: React.lazy(() => import('./l1')),
    },
    {
      id: 'support_play_skills',
      title: '8.2 Strategies to Support Play Skills',
      content: React.lazy(() => import('./l2')),
    },
    {
      id: 'support_social_and_emotional_skills',
      title: '8.3 Strategies to Support Social and Emotional Skills',
      content: React.lazy(() => import('./l3')),
    },
    {
      id: 'support_communication_skills',
      title: '8.4 Strategies to Support Communication Skills',
      content: React.lazy(() => import('./l4')),
    },
    {
      id: 'support_motor_skills',
      title: '8.5 Strategies to Support Motor Skills',
      content: React.lazy(() => import('./l5')),
    },
  ],
} as Topic;
