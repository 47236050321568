import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, createStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme =>
  createStyles({
    summary: {
      ...theme.typography.h6,
    },
    primary: {
      flexBasis: '33.33%',
      flexShrink: 0,
      flexGrow: 1,
    },
    secondary: {
      fontFamily: 'Roboto',
      color: theme.palette.text.secondary,
    },
    content: {
      ...theme.typography.body1,
      flex: 1,
      padding: theme.spacing(2),
      '& > p': {
        margin: theme.spacing(1, 0),
      },
    },
  }),
);

export type AccordionItemProps = {
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  children: React.ReactNode;
};

export default function AccordionItem(props: AccordionItemProps) {
  const { primary, secondary, children } = props;
  const classes = useStyles();
  return (
    <Accordion>
      <AccordionSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
        <span className={classes.primary}>{primary}</span>
        {secondary && <span className={classes.secondary}>{secondary}</span>}
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.content}>{children}</div>
      </AccordionDetails>
    </Accordion>
  );
}
