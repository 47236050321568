import React from 'react';
import { Container, Typography, Box } from '@material-ui/core';

import { AppPage } from 'app/shared';
import { Article, P } from 'components/lesson';

export default function Team() {
  return (
    <AppPage title="Team" responsive={false} fixed gutter>
      <Box my={4}>
        <Container maxWidth="md">
          <Article>
            <h3>About Us</h3>
            <P>
              The SEED Program has been co-designed with early years and primary school educators and teachers,
              psychologists and allied health professionals from bestchance Child Family Care and The Cheshire School,
              in partnership with psychology researchers at the Health and Social Care Unit in the School of Public
              Health and Preventive Medicine at Monash University.
            </P>
            <P>Our team currently includes:</P>
            <Typography variant="h6">Dr Claire Blewitt</Typography>
            <P>
              <em>
                Claire is a Research Fellow in the Health and Social Care Unit at Monash University, specialising in
                early childhood social and emotional development, pedagogical intervention, implementation and
                evaluation.
              </em>
            </P>
            <Typography variant="h6">Angela North</Typography>
            <P>
              <em>
                Angela is SEED's psychologist and content writer, and has 30 years of experience working with young
                children and their families. Angela has applied play therapy and other relationship-based approaches to
                building children's social and emotional skills, in both clinical and educational settings.
              </em>
            </P>
            <Typography variant="h6">Nicci Godsman</Typography>
            <P>
              <em>
                Nicci is the General Manager, Children & Family Services at bestchance Child Family Care. Nicci has
                worked across Government and non-Government roles in the Training and Education (K-12) sector as a
                senior leader. She has significant experience working with children and youth who face behavioural,
                social and emotional challenges.
              </em>
            </P>
            <Typography variant="h6">Professor Helen Skouteris</Typography>
            <P>
              <em>
                Helen is the Head of the Health and Social Care Unit in the School of Public Health and Preventive
                Medicine. She is an expert in child and parental health and wellbeing, and has worked extensively with
                early childhood and social care organisations over the past 20 years.
              </em>
            </P>
            <P>
              We extend our sincere thanks and acknowledgement to previous team members who have contributed knowledge,
              expertise and time to build SEED.
            </P>
          </Article>
        </Container>
      </Box>
    </AppPage>
  );
}
