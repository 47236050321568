import { StepField } from 'components/StepFrom';
import { isEmpty, uniq } from 'lodash';
import environments from 'contents/environments';
import { Lesson } from 'contents/types';

const steps: StepField[] = [
  {
    id: 'q1',
    label: 'Do you have a calming corner?',
    description:
      'This is a quiet space in your room where children can go to calm down. It might include a tent, partition, cushions, blanket or sensory toys.',
    helperText: 'A calming corner is a safe, inviting and quiet space (e.g. tent) where children can go to calm down.',
    type: 'toggle-group',
    options: ['Yes', 'No'],
    required: true,
  },
  {
    id: 'q2',
    label: 'Do children use the calming corner to calm down?',
    type: 'toggle-group',
    options: ['Yes', 'No or N/A'],
    required: true,
  },
  {
    id: 'q3',
    label: 'Do you have an active space?',
    description:
      'This is a dedicated area where children can safely release energy. It might include equipment to help children do this such as a mini-trapoline, mat or foam equipment.',
    helperText:
      'An active space is a dedicated space within the room where children know they can go to safely release energy. This area might include specific toys to help children with this, such as a mini trampoline with handles, foam equipment, mat or exercise ball.',
    type: 'toggle-group',
    options: ['Yes', 'No'],
    required: true,
  },
  {
    id: 'q4',
    label: 'Do you have a range of different play and learning spaces throughout your room?',
    type: 'toggle-group',
    options: ['Yes', 'No'],
    required: true,
  },
  {
    id: 'q5',
    label: 'Do you have a range of sensory furniture options within your room?',
    description: 'for e.g., sensory cushions, wobble chairs, bean bags or therapy swing',
    helperText:
      'Sensory furniture might include sensory cushions, pea pods, exercise ball chairs, therapy swings or floor rockers.',
    type: 'toggle-group',
    options: ['Yes', 'No'],
    required: true,
  },
  {
    id: 'q6',
    label: 'Do you have a range of sensory toys and tools available?',
    description: 'for e.g. textured and stretchy toys, fidget toys or weighted toys',
    helperText:
      'There are many options for different sensory preferences including hand fidget toys, stress balls, textured, stretchy, squishy and spikey toys, chew toys and necklaces, weighted animals and noise reducing earphones.',
    type: 'toggle-group',
    options: ['Yes', 'No'],
    required: true,
  },
  {
    id: 'q7',
    label: 'What type of expressive toys do you have available in the room?',
    description:
      'These are toys that encourage children to express themselves. Examples include family toys, house furniture, scary toys such as sharks or snakes, dress-ups, masks, and toys that encourage creativity',
    helperText: 'Expressive Toy',
    type: 'text',
  },
  {
    id: 'q8',
    label: ' Do you use a visual timetable?',
    type: 'toggle-group',
    options: ['Yes', 'No'],
    required: true,
  },
  {
    id: 'q9',
    label: 'If you do use a visual timetable, do you refer to it with your group everyday?',
    type: 'toggle-group',
    options: ['Yes', 'No or N/A'],
  },
  {
    id: 'q10',
    label: 'Do you use any of the following? Please select all that apply.',
    type: 'select-multi',
    options: [
      'Cues before transitions (e.g. musical transitions)',
      'Feelings Thermometer (or other visual that encourages labelling of feeling)',
      'Visual Cards',
      'Positive Reward System',
    ],
  },
];

export const stepResultToEnvironments = (() => {
  const whenAny = (envIndex: number) => () => envIndex;

  const whenMatch = (option: string, envIndex: number) => (value: string) => {
    return value === option ? envIndex : undefined;
  };

  const anyUnselected = (options: string[], envIndexes: number[]) => (values: string[]): number[] => {
    if (isEmpty(values)) {
      return envIndexes;
    }

    return options
      .map((option, i) => (values.indexOf(option) < 0 ? null : envIndexes[i]))
      .filter(envIndex => envIndex !== null) as number[];
  };

  return (values: any): Lesson[] => {
    let indexes = Object.entries({
      q1: whenMatch('No', 0),
      q3: whenMatch('No', 1),
      q4: whenMatch('No', 3),
      q5: whenMatch('No', 2),
      q6: whenMatch('No', 8),
      q7: whenAny(9),
      q8: whenMatch('No', 4),
      q10: anyUnselected(steps[9].options!, [5, 6, 7, 10]),
    })
      .map(([id, rule]) => rule(values[id]))
      .filter(value => !!value);
    return uniq(indexes.flat() as number[]).map<Lesson>(index => environments[index]);
  };
})();

export default steps;
