import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { app } from 'firebaseApp';
import { AppThunk, VideoEvent } from 'app/store';
import { ActionStatus, request, idle, failure } from 'app/helper';

export type VideoState = {
  videos: VideoEvent[] | null;
  status: ActionStatus | null;
};

export const initialState: VideoState = {
  videos: null,
  status: null,
};

const { actions, reducer } = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    setVideos(state, action: PayloadAction<VideoEvent[]>) {
      state.videos = action.payload;
      state.status = null;
    },

    request: request('status'),
    idle: idle('status'),
    failure: failure('status'),
  },
  extraReducers: {
    notAuthenticated() {
      return initialState;
    },
  },
});

export default reducer;

export { actions };

export const loadVideos = (): AppThunk => async (dispatch, state) => {
  actions.request();
  try {
    const snapshot = await app
      .firestore()
      .collection('/videos')
      .get();
    const videos = snapshot.docs.map<VideoEvent>(
      doc =>
        ({
          id: doc.id,
          ...doc.data(),
        } as VideoEvent),
    );
    dispatch(actions.setVideos(videos));
  } catch (err) {
    actions.failure(err.message || 'Failed to load video feed');
  }
};
