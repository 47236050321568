import { styled } from '@material-ui/core';
import { themeColors } from 'app/theme';

const listStyle = {
  marginBottom: '1.58rem',
  padding: 0,
};

export const Article = styled('article')(({ theme }) => ({
  padding: theme.spacing(2, 0, 4, 0),
  '& h2': {
    ...theme.typography.h4,
    fontWeight: 600,
  },
  '& h3': {
    ...theme.typography.h5,
    fontWeight: 600,
  },
  '& h4': {
    ...theme.typography.h6,
    fontWeight: 600,
  },

  '& figure ': {
    padding: 0,
    margin: theme.spacing(0, 0, 2, 0),
    '&  > figcaption': {
      ...theme.typography.caption,
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    '& > img': {
      width: '100%',
    },
  },
}));

export const P = styled('p')({
  fontSize: '1.2rem',
  fontFamily: "'Lora', serif",
  fontWeight: 400,
  lineHeight: 1.5,
  marginBottom: '1.58rem',
  // wordWrap: 'break-word',
  wordBreak: 'normal',
  whiteSpace: 'normal',
  overflowWrap: 'normal',
  textAlign: 'justify',
  hyphens: 'none',
});

export const UL = styled('ul')({
  ...listStyle,
  '& li': {
    display: 'list-item',
    padding: '5px 0',
    marginLeft: '1em',
    marginBottom: '0.5em',
    listStyle: 'square',
    fontFamily: "'Lora', serif",
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: '1.2rem',
  },
});

export const OL = styled('ol')({
  ...listStyle,
  '& li': {
    display: 'list-item',
    padding: '5px 0',
    marginLeft: '1em',
    marginBottom: '0.5em',
    listStyle: 'decimal',
    fontFamily: "'Lora', serif",
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: '1.2rem',
  },
});

export const Image = styled('img')({
  display: 'block',
  maxWidth: '100%',
  margin: '2em auto',
});

export const Blockquote = styled('blockquote')({
  position: 'relative',
  margin: '1em auto',
  color: '#666',
  background: '#f0f0f0',
  padding: '1em 20px 0.5em 50px',
  borderLeft: `8px solid ${themeColors.celadonBlue}`,
  lineHeight: 1.6,
  '&:before': {
    position: 'absolute',
    color: themeColors.celadonBlue,
    fontSize: '4em',
    left: 10,
    top: -10,
  },
  '&:after': {
    content: "''",
  },
  '& em': {
    display: 'block',
    color: '#333333',
    fontStyle: 'normal',
    fontWeight: 600,
    marginTop: '1em',
  },
});
