import React from 'react';
import { List, ListItemIcon, Avatar, ListItemText, ListItemSecondaryAction, Divider } from '@material-ui/core';
import DashboardCard from 'components/DashboardCard';
import ProgressCircle from 'components/ProgressCircle';
import { topics } from 'contents';
import LibraryIcon from '@material-ui/icons/ViewComfy';
import LinkItem from 'components/LinkItem';
import { useProgresses } from 'app/helper';

export default function TopicsCard() {
  const progresses = useProgresses();
  return (
    <DashboardCard title="My progress" avatar={<LibraryIcon />}>
      <List>
        {topics.map(topic => (
          <React.Fragment key={topic.id}>
            <Divider />
            <LinkItem to={`/topics/${topic.id}/${topic.entry}`}>
              <ListItemIcon>
                <Avatar variant="rounded" src={topic.cover} />
              </ListItemIcon>
              <ListItemText primary={topic.title} secondary={topic.description} />
              <ListItemSecondaryAction>
                <ProgressCircle value={progresses[topic.id] * 100} />
              </ListItemSecondaryAction>
            </LinkItem>
          </React.Fragment>
        ))}
      </List>
    </DashboardCard>
  );
}
