import React from 'react';
import { styled, StyledProps, Theme, Typography } from '@material-ui/core';

export type BannerProps = {
  src: string;
  height?: number;
};

export const Banner = styled((props: StyledProps & BannerProps) => {
  const { className } = props;
  return <div className={className} />;
})(({ theme, src, height }: BannerProps & { theme: Theme }) => ({
  width: '100%',
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  margin: theme.spacing(2, 0),
  minHeight: height || 400,
  borderRadius: 12,
  overflow: 'hidden',
}));

export const Figure = styled((props: StyledProps & { src: string; caption?: string; width?: string }) => {
  const { className, src, caption, width } = props;
  return (
    <figure className={className}>
      <img src={src} alt={caption || src} style={{ width: width || '100%' }} />
      {caption && (
        <Typography className="caption" variant="caption">
          {caption}
        </Typography>
      )}
    </figure>
  );
})(({ theme }) => ({
  width: '100%',
  margin: theme.spacing(2, 0),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& > img': {
    borderRadius: 12,
  },
  '& > .caption': {
    marginTop: theme.spacing(1),
  },
}));
