import React from 'react';
import { Box, Button, createStyles, makeStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';

const useStyles = makeStyles(theme =>
  createStyles({
    root: (props: StrategyButtonProps) => {
      const { bg } = props;
      return {
        width: '100%',
        backgroundColor: bg,
        color: theme.palette.getContrastText(bg),
        padding: theme.spacing(3),
        display: 'block',
        flex: 1,
        ...theme.typography.button,
        textAlign: 'center',
        '&:hover': {
          backgroundColor: bg,
        },
      };
    },
  }),
);

export type StrategyButtonProps = {
  to: string;
  bg: string;
  children: React.ReactNode;
};

export const StrategyButton = (props: StrategyButtonProps) => {
  const { to, children } = props;
  const classes = useStyles(props);
  const { topicId, lessonId } = useParams<{ topicId: string; lessonId: string }>();
  const history = useHistory();
  const linkTo = (to: string) => () => {
    history.push({ pathname: to, state: { breadcrumb: { topicId, lessonId } } });
  };
  return (
    <Button className={classes.root} onClick={linkTo(to)}>
      <Box display="flex" alignItems="center" justifyContent="center" minHeight={40}>
        <span>{children}</span>
      </Box>
    </Button>
  );
};
