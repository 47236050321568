import React from 'react';
import {
  Paper,
  createStyles,
  Typography,
  Box,
  withStyles,
  WithStyles,
  Theme,
  Avatar,
  PaperProps,
} from '@material-ui/core';
import blueGrey from '@material-ui/core/colors/blueGrey';

import orange from '@material-ui/core/colors/orange';
import deepPurple from '@material-ui/core/colors/deepPurple';
import pink from '@material-ui/core/colors/pink';
import teal from '@material-ui/core/colors/teal';
import indigo from '@material-ui/core/colors/indigo';

const colors = {
  white: { bg: '#FFF', secondary: 'rgba(0,0,0,.3)' },
  default: { bg: blueGrey[50], secondary: 'rgba(0,0,0,.3)' },
  orange: { bg: orange[200], secondary: 'rgba(0,0,0,.3)' },
  deepPurple: { bg: deepPurple[500], secondary: 'rgba(255,255,255,.5)' },
  pink: { bg: pink[500], secondary: 'rgba(255,255,255,.5)' },
  teal: { bg: teal[500], secondary: 'rgba(255,255,255,.5)' },
  indigo: { bg: indigo[500], secondary: 'rgba(255,255,255,.5)' },
  honeydrew: { bg: '#F1FAEE', secondary: 'rgba(0,0,0,.6)' },
  celadonBlue: { bg: '#457B9D', secondary: 'rgba(255,255,255,.5)' },
  powerBlue: { bg: '#A8DADC', secondary: 'rgba(255,255,255,.5)' },
  prussianBlue: { bg: '#1D3557', secondary: 'rgba(255,255,255,.5)' },
};

const styles = (theme: Theme) =>
  createStyles({
    root: (props: CardProps) => {
      const { bg } = colors[props.bg || 'default'];
      return {
        margin: theme.spacing(2, 0),
        padding: theme.spacing(4),
        backgroundColor: bg,
        borderRadius: 12,
        color: theme.palette.getContrastText(bg),
        border: props.outline ? `3px solid ${blueGrey[100]}` : 'none',
        flex: 1,
        overflow: 'hidden',
      };
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    secondary: (props: CardProps) => {
      const { secondary } = colors[props.bg || 'default'];
      return {
        ...theme.typography.subtitle1,
        color: secondary,
      };
    },

    default: {
      color: blueGrey[700],
    },
  });

export type CardProps = {
  bg?:
    | 'default'
    | 'pink'
    | 'teal'
    | 'indigo'
    | 'orange'
    | 'deepPurple'
    | 'white'
    | 'honeydrew'
    | 'celadonBlue'
    | 'powerBlue'
    | 'prussianBlue';
  outline?: boolean;
  avatar?: string;
  title?: string;
  subheader?: React.ReactNode;
  children?: React.ReactNode;
} & Pick<PaperProps, 'style'>;

export const Card = withStyles(styles)((props: CardProps & WithStyles<typeof styles>) => {
  const { children, avatar, title, subheader, classes, outline, ...other } = props;
  return (
    <Paper className={classes.root} elevation={0} {...other}>
      {(avatar || title || subheader) && (
        <Box mb={2} display="flex" alignItems="center">
          {avatar && <Avatar className={classes.avatar} src={avatar} variant="rounded" />}
          <Box>
            {title && <Typography variant="h3">{title}</Typography>}
            {subheader && <Box className={classes.secondary}>{subheader}</Box>}
          </Box>
        </Box>
      )}
      {children}
    </Paper>
  );
});
