import React from 'react';
import { Container, Box } from '@material-ui/core';
import { styled, StyledProps } from '@material-ui/core/styles';
import LinkButton from 'components/LinkButton';
import { Paragraph } from 'app/shared';

export default styled((props: StyledProps) => (
  <section className={props.className}>
    <Container maxWidth="lg">
      <Paragraph>
        The Cheshire SEED Educational Program is a comprehensive learning tool for early childhood educators looking to
        build expertise in fostering children’s social and emotional development. Educators are supported to build on
        their existing knowledge and skill through interactive modules. SEED can be utilised to focus on expanding
        general knowledge for the benefit of all children in your room, or to plan a supportive intervention for a
        particular child with challenging behaviours
      </Paragraph>
      <Box display="flex" justifyContent="center" my={4}>
        <LinkButton variant="contained" color="primary" to="/journey">
          Learn More
        </LinkButton>
      </Box>
    </Container>
  </section>
))(props => ({
  margin: props.theme.spacing(8, 0, 4, 0),
}));
