import React from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@material-ui/core/AppBar';
import { Toolbar, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

type StyleProps = {
  drawerWidth: number;
  responsive?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: (props: StyleProps) => ({
      transition: 'all 0.5s',
      [theme.breakpoints.up('lg')]: props.responsive
        ? {
            width: `calc(100% - ${props.drawerWidth}px)`,
            marginLeft: props.drawerWidth,
          }
        : undefined,
    }),

    menuButton: (props: StyleProps) => ({
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('lg')]: props.responsive
        ? {
            display: 'none',
          }
        : undefined,
    }),
    title: {
      flexGrow: 1,
    },
  }),
);

export type AppBarProps = {
  menu?: React.ReactNode;
  title?: string;
  actions?: React.ReactNode;
  responsive?: boolean;
} & MuiAppBarProps;

export default function AppBar(props: AppBarProps) {
  const { title, responsive, menu, actions, ...other } = props;
  const classes = useStyles({ drawerWidth: 300, responsive });
  return (
    <MuiAppBar className={classes.appBar} {...other}>
      <Toolbar>
        {menu && <div className={classes.menuButton}>{menu}</div>}

        <Typography variant="h6" className={classes.title} noWrap>
          {title}
        </Typography>

        {actions}
      </Toolbar>
    </MuiAppBar>
  );
}

AppBar.defaultProps = {
  responsive: true,
};
