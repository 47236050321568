import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Signin from './Signin';
import ResetPassword from './ResetPassword';
import Activate from './Activate';

export default function AuthRoutes() {
  return (
    <Switch>
      <Route exact path="/auth/activate" component={Activate} />
      <Route exact path="/auth/signin" component={Signin} />
      <Route exact path="/auth/resetPassword" component={ResetPassword} />
      <Route render={() => <Redirect to="/404" />} />
    </Switch>
  );
}
