import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SpacingProps } from '@material-ui/system';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoContainer: {
      position: 'relative',
      overflow: 'hidden',
      paddingTop: '56.25%',
      margin: theme.spacing(1, 0),
    },
    videoInner: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    caption: {
      ...theme.typography.caption,
      color: theme.palette.text.secondary,
      textAlign: 'center',
    },
  }),
);

type VideoProps = SpacingProps & {
  id: string;
  title?: React.ReactNode;
};

/**
 * Video Component wrap around react-youtube
 *
 * @param {object} props React props
 */
export default function Video(props: VideoProps) {
  const { id, title } = props;
  const classes = useStyles();
  return (
    <Box mx="auto" my={4}>
      <div className={classes.videoContainer}>
        <iframe
          className={classes.videoInner}
          src={`https://www.youtube.com/embed/${id}`}
          frameBorder="0"
          title="Youtube"
        />
      </div>
      <Typography className={classes.caption}>{title}</Typography>
    </Box>
  );
}
