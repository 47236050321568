import React from 'react';
import { Container, Grid, Box, makeStyles, createStyles } from '@material-ui/core';
import Header from './Header';
import { AppPage } from 'app/shared';
import TopicsCard from 'app/topics/TopicsCard';
import ProfileCard from 'app/profile/ProfileCard';
import VideosCard from 'app/videos/VideosCard';

const useStyles = makeStyles(theme =>
  createStyles({
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

export default function Dashboard() {
  const classes = useStyles();
  return (
    <AppPage title="My Dashboard" responsive={false}>
      <Header />
      <Container maxWidth="lg">
        <Box mt={4} mb={7}>
          <Grid container spacing={4} alignItems="stretch">
            <Grid item xs={12} lg={6} className={classes.gridItem}>
              <ProfileCard />
            </Grid>
            <Grid item xs={12} lg={6} className={classes.gridItem}>
              <VideosCard />
            </Grid>
            <Grid item xs={12}>
              <TopicsCard />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </AppPage>
  );
}
