import React from 'react';
import { Topic } from 'contents/types';
import lightBlue from '@material-ui/core/colors/lightBlue';

export default {
  id: 'my_strategies',
  entry: 'overview',
  title: '4. My Strategies',
  description:
    'Build your knowledge in therapeutic and positive behaviour strategies that foster children’s self-esteem, resilience and self-regulation.',
  cover: '/assets/m4/cover.jpg',
  themeColor: lightBlue[300],
  lessons: [
    {
      id: 'overview',
      title: '4.0 Overview and Learning Outcomes',
      content: React.lazy(() => import('./l0')),
    },
    {
      id: 'three_r',
      title: "4.1 The Three R's",
      content: React.lazy(() => import('./l1')),
    },
    {
      id: 'cheshire_rubrics',
      title: '4.2 The SEED Rubrics',
      content: React.lazy(() => import('./l2')),
    },
    {
      id: 'case_study_1',
      title: '4.3 Case Study #1',
      content: React.lazy(() => import('./l3')),
    },
    {
      id: 'case_study_2',
      title: '4.4 Case Study #2',
      content: React.lazy(() => import('./l4')),
    },
    {
      id: 'have_your_say',
      title: '4.5 Have Your Say',
      content: React.lazy(() => import('./l5')),
    },
  ],
} as Topic;
