import React from 'react';
import FeedbackForm, { FeedbackFormProps, FeedbackData } from './FeedbackForm';
import useContent from '../useContent';

export type ContentFeedbackFormProps = {
  id: string;
} & Omit<FeedbackFormProps, 'onSubmit' | 'data'>;

export default function ContentFeedbackForm(props: ContentFeedbackFormProps) {
  const { id, ...formProps } = props;
  const [content, setContent] = useContent(id);
  const handleSubmit = (data: FeedbackData) => {
    setContent(data);
  };

  return <FeedbackForm {...formProps} onSubmit={handleSubmit} data={content || null} />;
}
