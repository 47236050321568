import React from 'react';
import { TextField } from '@material-ui/core';
import { RadioGroup } from 'components/form';
import { QuestionType } from './types';
import ToggleGroup from './ToggleGroup';
import SelectionMulti from './SelectionMulti';

export type StepQuestionProps = {
  id: string;
  variant?: QuestionType;
  options?: string[];
  helperText?: string;
  value: any;
  onChange: (value: any) => void;
};

export default function StepQuestion(props: StepQuestionProps) {
  const { id, variant, options, value, helperText, onChange } = props;

  switch (variant) {
    case 'select':
      return <RadioGroup value={value || null} options={options || []} onChange={e => onChange(e.target.value)} />;

    case 'select-multi':
      return <SelectionMulti values={value} options={options || []} onChange={onChange} />;

    case 'toggle-group':
      return <ToggleGroup value={value || null} options={options || []} onChange={onChange} />;

    default:
      return (
        <TextField
          id={id}
          variant="outlined"
          multiline
          rows={3}
          value={value || ''}
          helperText={helperText}
          onChange={e => onChange(e.target.value)}
          fullWidth
        />
      );
  }
}
