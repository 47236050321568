import React from 'react';
import { Button, makeStyles, createStyles, Avatar, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import orange from '@material-ui/core/colors/orange';
import deepPurple from '@material-ui/core/colors/deepPurple';
import pink from '@material-ui/core/colors/pink';
import teal from '@material-ui/core/colors/teal';
import indigo from '@material-ui/core/colors/indigo';

const colors = {
  white: '#FFF',
  orange: orange[200],
  deepPurple: deepPurple[500],
  pink: pink[500],
  teal: teal[500],
  indigo: indigo[500],
  honeydrew: '#F1FAEE',
  celadonBlue: '#457B9D',
  powerBlue: '#A8DADC',
  prussianBlue: '#1D3557',
};

export type LinkButtonXProps = {
  color?:
    | 'pink'
    | 'teal'
    | 'indigo'
    | 'orange'
    | 'deepPurple'
    | 'white'
    | 'honeydrew'
    | 'celadonBlue'
    | 'powerBlue'
    | 'prussianBlue';
  avatar?: string;
  href?: string;
  to?: string;
  label: string;
};

const useStyles = makeStyles(theme =>
  createStyles({
    button: (props: LinkButtonXProps) => {
      const bg = colors[props.color || 'indigo'];
      return {
        backgroundColor: bg,
        color: theme.palette.getContrastText(bg),
        padding: theme.spacing(3),
        display: 'block',
        flex: 1,
        ...theme.typography.button,
        textAlign: 'center',
        '&:hover': {
          backgroundColor: bg,
        },
      };
    },
    buttonContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
  }),
);

export function LinkButtonX(props: LinkButtonXProps) {
  const { to, href, avatar, label } = props;
  const classes = useStyles(props);
  const history = useHistory();
  const linkProps = to
    ? {
        href: to,
        onClick: (e: React.MouseEvent) => {
          e.preventDefault();
          history.push(to);
        },
      }
    : {
        href: href || '#',
        target: '_blank',
        rel: 'noopener noreferrer',
      };

  return (
    <Button className={classes.button} {...linkProps}>
      <Box display="flex" alignItems="center" justifyContent="center" minHeight={40}>
        {avatar && <Avatar className={classes.avatar} variant="rounded" src={avatar} />}
        <span>{label}</span>
      </Box>
    </Button>
  );
}
