import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Link, { LinkProps } from '@material-ui/core/Link';

export type BreadcrumbLinkProps = LinkProps & {
  to: string;
};

export function BreadcrumbLink(props: BreadcrumbLinkProps) {
  const { to, ...linkProps } = props;
  const { topicId, lessonId } = useParams<{ topicId: string; lessonId: string }>();
  const history = useHistory();

  const handleLink = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    history.push({ pathname: to, state: { breadcrumb: { topicId, lessonId } } });
  };

  return <Link {...linkProps} href={to} onClick={handleLink} />;
}
