import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { List, Box, ListItemText, ListItemIcon, Divider, Typography } from '@material-ui/core';
import { NavTopics } from './NavTopics';
import ListSubheader from './ListSubheader';
import Logo from 'components/Logo';
import LinkItem from 'components/LinkItem';
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { selectAuth } from 'app/store';

const useStyles = makeStyles(theme =>
  createStyles({
    aside: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.light,
      flex: 1,
    },
    divider: {
      backgroundColor: theme.palette.primary.light,
      opacity: 0.3,
    },
    header: {
      // padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 2),
      ...theme.mixins.toolbar,
    },
    logo: {
      marginRight: theme.spacing(1),
    },
    title: {
      ...theme.typography.h6,
      fontWeight: 300,
    },

    icon: {
      color: 'inherit',
    },
    selected: {
      backgroundColor: 'rgba(0,0,0,0.2) !important',
    },
  }),
);

export function AppDrawer() {
  const classes = useStyles();
  const { authenticated } = useSelector(selectAuth);

  return (
    <aside className={classes.aside}>
      <header className={classes.header}>
        <Logo className={classes.logo} size="medium" />
        <Typography className={classes.title}>
          Cheshire <strong>SEED</strong>
        </Typography>
      </header>

      <Divider className={classes.divider} />

      <Box flex={1} overflow="auto" component="nav">
        <List>
          <ListSubheader>Main</ListSubheader>
          <LinkItem exact to="/" classes={{ selected: classes.selected }}>
            <ListItemIcon className={classes.icon}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </LinkItem>
          <LinkItem exact to="/dashboard" classes={{ selected: classes.selected }}>
            <ListItemIcon className={classes.icon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </LinkItem>

          {authenticated && <NavTopics />}

          <ListSubheader>Quick Links</ListSubheader>
          <LinkItem exact to="/journey" classes={{ selected: classes.selected }}>
            <ListItemText primary="Our Journey" />
          </LinkItem>

          <LinkItem exact to="/team" classes={{ selected: classes.selected }}>
            <ListItemText primary="Team" />
          </LinkItem>
        </List>
      </Box>
    </aside>
  );
}
