import React from 'react';
import { AppPage } from 'app/shared';
import { Container, Typography } from '@material-ui/core';

export default function EditProfile() {
  return (
    <AppPage title="Edit Profile" fixed gutter>
      <Container maxWidth="md">
        <Typography variant="h6">My profile</Typography>
      </Container>
    </AppPage>
  );
}
