import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Typography, Button, Grid, Chip } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import StepForm, { StepFormData } from 'components/StepFrom';
import { Lesson } from 'contents/types';
import { Card } from '../Card';
import steps, { stepResultToEnvironments } from './steps';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      backgroundColor: '#FFF',
    },

    paper: {
      margin: theme.spacing(2, 0),
    },

    chip: {
      ...theme.typography.body1,
      width: '100%',
    },
  }),
);

export type EnvironmentWizardProps = {
  data: StepFormData;
  setData: (data: StepFormData) => void;
};

export default function EnvironmentWizard(props: EnvironmentWizardProps) {
  const { data, setData } = props;
  const classes = useStyles();
  const { topicId, lessonId } = useParams<{ topicId: string; lessonId: string }>();
  const [lessons, setLessons] = React.useState<Lesson[] | null>(null);
  const history = useHistory();

  React.useEffect(() => {
    if (data.activeStep >= steps.length) {
      const lessons = stepResultToEnvironments(data.values);
      setLessons(lessons);
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (nextData: StepFormData) => {
    setData(nextData);
    if (nextData.activeStep >= steps.length) {
      const lessons = stepResultToEnvironments(nextData.values);
      setLessons(lessons);
    }
  };

  const handleRestart = () => {
    setLessons(null);
    setData({ ...data, activeStep: 0 });
  };

  const completed = lessons !== null;

  return (
    <Card title="Environments" subheader="Your Room Environment" outline>
      <StepForm data={data} steps={steps} onChange={handleChange} />

      {completed && (
        <Box mt={4}>
          <Typography>
            Based on what you told us about your room environment, the following strategies might be especially
            relevant:
          </Typography>

          <Grid className={classes.paper} container spacing={2}>
            {lessons!.map(lesson => (
              <Grid key={lesson.id} item xs={12} md={6}>
                <Chip
                  color="primary"
                  className={classes.chip}
                  label={lesson.title}
                  onClick={() =>
                    history.push(`/topics/environments/${lesson.id}`, { breadcrumb: { topicId, lessonId } })
                  }
                />
              </Grid>
            ))}
          </Grid>

          <Typography>
            Be sure to have a look through the other strategies in Module 3. You might already be using some (or most)
            of these techniques in your room, however this module will offer tips to maximise the benefit of these
            strategies with individual children as well as the broader group.
          </Typography>

          <Box mt={4}>
            <Button color="primary" fullWidth onClick={handleRestart}>
              Retry
            </Button>
          </Box>
        </Box>
      )}
    </Card>
  );
}
