import foundations from './01_foundations';
import my_priorities from './02_my_priorities';
import my_environment from './03_my_environment';
import my_strategies from './04_my_strategies';
import my_learning from './05_my_learning';
import making_play_therapeutic from './06_making_play_therapeutic';
import talking_with_caregivers from './07_talking_with_caregivers';
import supporting_additional_needs from './08_supporting_additional_needs';
import environments from './environments';
import strategies from './strategies';
import foundationalstrategies from './foundationalstrategies';
import interactions from './interactions';

import anxiety from './09_anxiety';
import anxietyStrategies from './09_anxiety/strategies';
import autism from './10_autism';
import autismStrategies from './10_autism/strategies';

export * from './types';
export { environments, strategies, foundationalstrategies, interactions, anxietyStrategies, autismStrategies };
export const topics = [
  foundations,
  my_priorities,
  my_environment,
  my_strategies,
  my_learning,
  making_play_therapeutic,
  talking_with_caregivers,
  supporting_additional_needs,
  anxiety,
  autism,
];
