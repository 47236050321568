import React from 'react';
import { Topic } from 'contents/types';
import orange from '@material-ui/core/colors/orange';
import cover from './assets/cover.png';

export default {
  id: 'autism',
  entry: 'overview',
  title: '10. Supporting Children with Autism',
  description: 'Autism can present significant challenges for both the child and their educators.',
  cover,
  themeColor: orange[600],
  lessons: [
    {
      id: 'overview',
      title: '10.0 Overview and Learning Outcomes',
      content: React.lazy(() => import('./l0')),
    },
    {
      id: 'what-is-it',
      title: '10.1 What is Autism',
      content: React.lazy(() => import('./l1')),
    },
    {
      id: 'challenges',
      title: '10.2 Challenges for Children with Autism',
      content: React.lazy(() => import('./l2')),
    },
    {
      id: 'support-children-with-autism',
      title: '10.3 Strategies to Support Children with Autism',
      content: React.lazy(() => import('./l3')),
    },
    {
      id: 'play-and-engagement',
      title: '10.4 Strategies to Support Play and Engagement',
      content: React.lazy(() => import('./l4')),
    },
    {
      id: 'address-challenging-behaviour',
      title: '10.5 Strategies to Address Challenging Behaviour',
      content: React.lazy(() => import('./l5')),
    },
    {
      id: 'sensory-processing',
      title: '10.6 Strategies to Support Sensory Processing',
      content: React.lazy(() => import('./l6')),
    },
    {
      id: 'talking-with-caregivers',
      title: '10.7 Working with Caregivers',
      content: React.lazy(() => import('./l7')),
    },
  ],
} as Topic;
