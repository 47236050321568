import React from 'react';
import { Container, Grid, Typography, Hidden } from '@material-ui/core';
import { styled, StyledProps } from '@material-ui/core/styles';

export default styled((props: StyledProps) => (
  <header className={props.className}>
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12} md={6} className="first-heading">
          <Typography variant="h2">My Dashboard</Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} md={6} className="second-walking"></Grid>
        </Hidden>
      </Grid>
    </Container>
  </header>
))(({ theme }) => ({
  position: 'relative',

  paddingBottom: theme.spacing(4),
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.dark,
  '& .first-heading': {
    marginTop: 100,
  },
  '& .second-walking': {
    position: 'relative',
    '& > img': {
      position: 'absolute',
      zIndex: 10,
      width: 'calc(100% - 100)',
      top: 120,
      left: 60,
    },
  },
}));
