import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
} from '@material-ui/core';
import ExtLink from 'components/ExtLink';

type DialogProps = {
  open: boolean;
  onClose: () => void;
};

export const TermsOfUse = ({ open, onClose }: DialogProps) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Terms of Use</DialogTitle>
    <DialogContent>
      <DialogContentText component="div">
        <Typography variant="h5">About this website</Typography>
        <Typography variant="body1">
          Cheshire SEED is an online program run by Bestchance Child Family Care and Monash University’s Monash Centre
          for Health Research and Implementation in the School of Public Health and Preventive Medicine. 
        </Typography>
        <Typography variant="body1">
          The Terms of Use for this program are outlined in your Intellectual Property Licence and Services Agreement.
        </Typography>
        <Typography variant="body1">
          If you have any questions about your agreement, please{' '}
          <ExtLink href="mailto:cheshireseed@bestchance.org.au?subject=CheshireSEED%20Feedback%20On%20Agreement">
            contact us
          </ExtLink>
        </Typography>
      </DialogContentText>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Agree
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
);

export const Accessibility = ({ open, onClose }: DialogProps) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Accessibility</DialogTitle>
    <DialogContent>
      <DialogContentText component="div">
        <Typography variant="h5" gutterBottom>
          Adobe PDF
        </Typography>
        <p>Access to some files on our site may require the installation of the following free PDF file viewer.</p>

        <figure>
          <img src="https://wwwimages2.adobe.com/downloadcenter/singlepage/live/images/reader_windows.gif" alt="pdf" />
        </figure>
        <p>
          Adobe PDF is a universal file format that retains all the elements of a printed document as an electronic
          image that can be viewed, navigated, forwarded to others, or printed exactly as it appears on screen, using
          the free Adobe Acrobat Reader software.
        </p>
        <p>
          <a href="https://get.adobe.com/reader">Download Adobe Acrobat Reader</a>
        </p>

        <Typography variant="h5" gutterBottom>
          Resize the text on this website
        </Typography>
        <p>
          The Cheshire SEED website will allow users to size the text in a web browser to meet their personal needs.
          In the event you would like to adjust your font size please refer to the instructions below for your specific
          web browser.
        </p>
        <Typography variant="h6">Chrome</Typography>
        <p>
          You can hold down the keyboard key labeled ‘Ctrl’ and press the keys ‘+’ or ‘-’ to increase or decrease the
          page zoom, respectively.
        </p>
        <p>
          Alternately, at the top of your browser window, select the menu icon (Customize and Control Google Chrome).
          Within the dropdown, there is the item ‘Zoom’. Press the up and down arrows to increase or decrease the page
          zoom.
        </p>

        <Typography variant="h6">Internet Explorer</Typography>
        <p>
          In the menu at the top of your browser window labeled ‘View’ there is an item labeled ‘Text Size’, which opens
          a sub-menu. This sub-menu should have the items ‘Largest’, ‘Larger’, ‘Medium’, ‘Smaller’, and ‘Smallest’. One
          of these items should be marked; this is your current font size. Selecting a higher font size will make the
          letters on the website larger (and possibly easier to read), while a lower font size will make the letters
          smaller, letting you see more of the web page at once.
        </p>
        <Typography variant="h6">Firefox</Typography>
        <p>
          You can hold down the keyboard key labeled ‘Ctrl’ and press the keys ‘+’ or ‘-’ to increase or decrease the
          page zoom, respectively.
        </p>
        <p>
          Alternately, in the menu at the top of your browser window labeled ‘View’ there is an item labeled ‘Zoom’
          which opens a sub-menu. This sub-menu should have the items ‘Zoom In’, ‘Zoom Out’, ‘Reset’, and ‘Zoom Text
          Only’. Selecting ‘Zoom Text Only’, and then ‘Zoom In’ will make the letters on the website larger (and
          possibly easier to read), while ‘Zoom Out’ will make the letters smaller, letting you see more of the page at
          once.
        </p>
        <Typography variant="h6">Safari</Typography>
        <p>
          You can hold down the “command” key (the one with the apple logo on it) and press the keys ‘+’ or ‘-’ to
          increase or decrease the page zoom, respectively.
        </p>
        <p>
          Alternately, in the menu at the top of your browser window labeled ‘View’ there are the items ‘Zoom In’, ‘Zoom
          Out’, and ‘Zoom Text Only’. Selecting ‘Zoom Text Only’, and then ‘Zoom In’ will make the letters on the
          website larger (and possibly easier to read), while ‘Zoom Out’ will make the letters smaller, letting you see
          more of the page at once.
        </p>
        <Typography variant="h6">Opera</Typography>
        <p>
          You can hold down the keyboard key labeled ‘Ctrl’ and press the keys ‘+’ or ‘-’ to increase or decrease the
          page zoom, respectively.
        </p>
        <p>
          Alternately, at the top of your browser window, select the Opera icon (Customize and Control Opera). Within
          the dropdown, there is the item ‘Zoom’. Press ‘+’ or ‘-’ to increase or decrease the page zoom.
        </p>
      </DialogContentText>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
);

export function Glossary(props: DialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Glossary</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Typography variant="h6"> Form of Behaviour</Typography>
          <Typography paragraph>
            The type of behaviour observed, such as shy, withdrawn, aggressive, oppositional, hyperactive, impulsive or
            emotionally reactive.
          </Typography>

          <Typography variant="h6"> Function of Behaviour</Typography>
          <Typography paragraph>
            The underlying purpose of the behaviour for the child. This might include gaining attention, gaining a
            desired object or activity, to avoid doing something, to protest against an unwanted activity or task, or to
            gain sensory input (a behaviour can serve one or multiple functions).
          </Typography>

          <Typography variant="h6"> Non-Preferred Task</Typography>
          <Typography paragraph>
            Any activity or task that the child does not want to engage in. This might be because the child finds the
            task difficult or frustrating, or they are less desirable than alternative activities.
          </Typography>

          <Typography variant="h6"> Preferred Task</Typography>
          <Typography paragraph>
            Any activity that the child wants to engage in. These are usually tasks that the child engages in regularly,
            is interested in and enjoys.
          </Typography>

          <Typography variant="h6"> Proprioception</Typography>
          <Typography paragraph>Awareness of the position and movement of the body.</Typography>

          <Typography variant="h6"> Protective Factors</Typography>
          <Typography paragraph>
            Any characteristics, attributes, experiences or events that reduce the likelihood that a child will
            experience mental health challenges. These may strengthen children’s resilience, helping them to
            successfully manage challenges they may face.
          </Typography>

          <Typography variant="h6"> Risk Factors</Typography>
          <Typography paragraph>
            Any characteristics, attributes, experiences or events that increase the likelihood that a child will
            experience mental health challenges during childhood or beyond.
          </Typography>

          <Typography variant="h6"> Sensory Processing (or Sensory Integration)</Typography>
          <Typography paragraph>
            The way the brain receives, organises and responds to input from the senses.
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default function ComingSoon(props: DialogProps & { title: string }) {
  const { title, open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="h3" paragraph>
          More Coming Soon
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
