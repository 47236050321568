import React from 'react';
import { styled, StyledProps } from '@material-ui/core/styles';

export const FootNotes = styled((props: StyledProps & { children: React.ReactNode }) => {
  return (
    <footer className={props.className}>
      <hr />
      {props.children}
    </footer>
  );
})(({ theme }) => ({
  ...theme.typography.caption,
  marginTop: theme.spacing(4),
  '& > hr': {
    width: '50%',
    marginInlineStart: 0,
    marginInlineEnd: 0,
  },
  '& > dl': {
    display: 'flex',
    flexDirection: 'row',
    '& > dt': {
      fontWeight: 600,
    },
    '& > dd': {
      marginLeft: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
  },
}));
