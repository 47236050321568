import React from 'react';
import { Container, Grid, Typography, Box, Hidden } from '@material-ui/core';
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import LinkButton from 'components/LinkButton';
import { H4 } from 'app/shared';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      minHeight: 300,
      paddingBottom: '10vw',
      backgroundColor: theme.palette.primary.light,
    },
    svg: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '10vw',
    },
    heading: {
      marginTop: 100,
      color: theme.palette.primary.dark,
    },
    title: {
      ...theme.typography.h2,
    },
    walking: {
      position: 'relative',
      '& > img': {
        position: 'absolute',
        zIndex: 10,
        width: 'calc(100% - 100)',
        top: 120,
        left: 60,
      },
    },
  });

export default withStyles(styles)((props: WithStyles<typeof styles>) => {
  const { classes } = props;
  return (
    <header className={classes.root}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} md={6} className={classes.heading}>
            <Typography variant="h2">
              Cheshire <strong>SEED</strong>
            </Typography>
            <H4>
              The <b>S</b>ocial and <b>E</b>motional <b>E</b>ngagement and <b>D</b>evelopment Educational Program
            </H4>
            <Box my={4}>
              <LinkButton to="/Dashboard" variant="contained" color="secondary">
                Start Now
              </LinkButton>
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={6} className={classes.walking}>
              <img src="images/landing/walk.png" alt="walk" />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
      <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon fill="#FAFAFA" points="0,100 100,0 100,100" />
      </svg>
    </header>
  );
});
