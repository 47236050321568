import React from 'react';
import { Typography, styled, StyledProps } from '@material-ui/core';

export type FieldProps = {
  label?: string;
  children: React.ReactNode;
};

export default styled((props: StyledProps & FieldProps) => {
  const { className, label, children } = props;
  return (
    <section className={className}>
      {label && (
        <Typography className="label" component="label">
          {label}
        </Typography>
      )}
      {children}
    </section>
  );
})(({ theme }) => ({
  margin: theme.spacing(0, 0, 3, 0),
  '& > .label': {
    ...theme.typography.subtitle1,
    display: 'block',
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
