import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import withAuth from './withAuth';
import { Home, Journey, Team, Research } from './landing';
import AuthRoutes from './auth';
import TopicRoutes from './topics';
import Dashboard from './dashboard';
import EditProfile from './profile/EditProfile';
import { useScreenView } from './helper';

export default function AppRoutes() {
  useScreenView('cheshire-seed');
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/journey" component={Journey} />
      <Route exact path="/team" component={Team} />
      <Route exact path="/research" component={Research} />
      <Route path="/auth" component={AuthRoutes} />
      <Route path="/dashboard" component={withAuth(Dashboard)} />
      <Route path="/topics" component={withAuth(TopicRoutes)} />
      <Route exact path="/profile/edit" component={withAuth(EditProfile)} />
      <Route exact path="/404" render={() => <h6>Not Found...</h6>} />
      <Redirect to="/404" />
    </Switch>
  );
}
