import React from 'react';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import { Errors, Validators, FormData } from './types';

export type ChildProps<T> = {
  values: T;
  updateField: (field: keyof T, value: any) => void;
  errors: Errors<T>;
};

export type FormProps<T> = Pick<React.HTMLProps<HTMLFormElement>, 'className' | 'noValidate' | 'ref'> & {
  data: T;
  validators?: Validators<T>;
  children: (props: ChildProps<T>) => React.ReactNode;
  onSubmit: (data: T) => void;
  onChange?: (data: T) => void;
  formRef?: React.MutableRefObject<HTMLFormElement | null>;
};

export function Form<T extends FormData>(props: FormProps<T>) {
  const { validators, onSubmit, onChange, children, formRef, ...others } = props;
  const [data, setData] = React.useState<T>(props.data);
  const [errors, setErrors] = React.useState<Errors<T>>({});

  const validate = (): Boolean => {
    if (!validators) {
      return true;
    }

    const errors = reduce<Validators<T>, Errors<T>>(
      validators,
      (errors, validator, field) => {
        const error = validator!(field, data);
        return error ? { ...errors, [field]: error } : errors;
      },
      {},
    );

    if (!isEmpty(errors)) {
      setErrors(errors);
      return false;
    } else {
      setErrors({});
      return true;
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) {
      onSubmit(data as T);
    }
  };

  const updateField = (field: keyof T, value: any) => {
    const nextData = { ...data, [field]: value };
    setData(nextData);
    onChange && onChange(nextData);
  };

  return (
    <form {...others} onSubmit={handleSubmit} ref={formRef}>
      {children({ values: data, updateField, errors })}
    </form>
  );
}
