import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { FactorContent } from './FactorPop';

const useStyles = makeStyles({
  avatar: {
    position: 'relative',
    width: 200,
    height: 200,
  },
  image: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
});

export type FactorDialogProps = {
  dialogContent: FactorContent | null;
  onClose: () => void;
};

export function FactorDialog(props: FactorDialogProps) {
  const { dialogContent, onClose } = props;
  const classes = useStyles();
  return (
    <Dialog open={dialogContent !== null} onClose={onClose}>
      {dialogContent && (
        <>
          <DialogTitle>{dialogContent.title}</DialogTitle>
          <DialogContent>
            <Box display="flex" justifyContent="center">
              <Avatar className={classes.avatar}>
                <span className={classes.image} style={{ backgroundImage: `url(${dialogContent.cover})` }} />
              </Avatar>
            </Box>
            <DialogContentText>{dialogContent.content}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
