import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { List, ListItem, Box, withStyles, Collapse, ListItemText, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListSubheader from './ListSubheader';
import LinkItem from 'components/LinkItem';
import { Topic, topics } from 'contents';
import { useProgresses } from 'app/helper';

const TopicItem = withStyles({
  root: {
    fontSize: 18,
  },
})(ListItem);

type StyleProps = {
  selected: boolean;
  color: string;
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: ({ selected, color }: StyleProps) => {
      const selectedStyles = selected
        ? {
            backgroundColor: 'rgba(0,0,0,0.2)',
          }
        : {};
      return {
        borderLeft: `5px solid ${color}`,
        ...selectedStyles,
      };
    },

    lessonRoot: {
      fontSize: 14,
      fontFamily: 'Roboto Condensed',
      color: theme.palette.primary.light,
    },

    lessonSelected: ({ color }: StyleProps) => ({
      color,
    }),

    progressItem: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 2,
      paddingBottom: 2,
    },

    progressRoot: {
      flex: 1,
      height: 2,
      marginRight: theme.spacing(1),
    },

    progressBarColorPrimary: ({ color }: StyleProps) => ({
      backgroundColor: color,
    }),
  }),
);

export type NavTopicProps = {
  topic: Topic;
  progress: number;
};

function NavTopic(props: NavTopicProps) {
  const { topic, progress } = props;
  const match = useRouteMatch({ path: `/topics/${topic.id}` });
  const classes = useStyles({ selected: Boolean(match), color: topic.themeColor });
  const [open, setOpen] = React.useState(!!match);

  React.useEffect(() => {
    if (!match && open) {
      setOpen(false);
    }
    // eslint-disable-next-line
  }, [match, setOpen]);

  return (
    <Box className={classes.root}>
      <TopicItem onClick={() => setOpen(!open)} button>
        <ListItemText primary={topic.title} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </TopicItem>

      <Collapse in={open}>
        <List>
          <ListItem className={classes.progressItem}>
            <LinearProgress
              classes={{ root: classes.progressRoot, barColorPrimary: classes.progressBarColorPrimary }}
              key={topic.id}
              value={progress}
              variant="determinate"
              color="primary"
            />
            <Typography variant="caption">{progress.toFixed(0)}%</Typography>
          </ListItem>
          {topic.lessons.map(lesson => {
            const lessonKey = [topic.id, lesson.id].join('#');
            const path = `/topics/${topic.id}/${lesson.id}`;
            return (
              <LinkItem
                classes={{ root: classes.lessonRoot, selected: classes.lessonSelected }}
                key={lessonKey}
                to={path}
                exact>
                {lesson.title}
              </LinkItem>
            );
          })}
        </List>
      </Collapse>
    </Box>
  );
}

export function NavTopics() {
  const progresses = useProgresses();
  return (
    <>
      <ListSubheader>Learning</ListSubheader>
      {topics.map(topic => (
        <NavTopic key={topic.id} topic={topic} progress={100 * (progresses[topic.id] || 0)} />
      ))}
    </>
  );
}
