import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    root: (props: LogoProps) => {
      const size = {
        small: 24,
        medium: 36,
        large: 72,
      }[props.size || 'small'];
      return {
        width: size * 1.5,
        height: size,
      };
    },
  }),
);

export type LogoProps = {
  size?: 'small' | 'medium' | 'large';
  className?: string;
};

export default function Logo(props: LogoProps) {
  const { className } = props;
  const classes = useStyles(props);
  return (
    <div className={className}>
      <img className={classes.root} src="/images/logos/cheshire_seed.png" alt="logo" />
    </div>
  );
}
