import React from 'react';
import { styled, Theme, StyledProps } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';

export type ProgressCircleProps = {
  value: number;
};

export default styled((props: StyledProps & { value: number }) => {
  return <span className={props.className}></span>;
})((props: ProgressCircleProps & { theme: Theme }) => {
  const { value } = props;
  const deg = ((360 * value) / 100).toFixed(0);
  return {
    display: 'inline-block',
    minWidth: 16,
    minHeight: 16,
    border: `1px solid ${blueGrey[500]}`,
    boxShadow: props.theme.shadows[1],
    backgroundColor: '#FFF',
    borderRadius: '50%',
    backgroundImage: `conic-gradient(${blueGrey[300]} ${deg}deg, #fff 0)`,
  };
});
