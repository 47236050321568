import React from 'react';
import { Lesson } from 'contents/types';

export default [
  {
    id: 'focus_relationship',
    title: 'Focus on Relationships',
    content: React.lazy(() => import('./l1')),
  },
  {
    id: 'function_behaviour',
    title: 'Function of Behaviour',
    content: React.lazy(() => import('./l2')),
  },
  {
    id: 'sensory_needs',
    title: 'Sensory Needs',
    content: React.lazy(() => import('./l3')),
  },
  {
    id: 'transitions',
    title: 'Transitions',
    content: React.lazy(() => import('./l4')),
  },
  {
    id: 'effective_visuals',
    title: 'Effective Visuals',
    content: React.lazy(() => import('./l5')),
  },
] as Lesson[];
