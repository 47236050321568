import React from 'react';
import Link, { LinkProps } from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';

export type RouteLinkProps = LinkProps & {
  to: string;
};

export default function RouteLink(props: RouteLinkProps) {
  const { to, ...linkProps } = props;
  const history = useHistory();
  const handleLink = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    history.push(to);
  };
  return <Link href={to} onClick={handleLink} {...linkProps} />;
}
