import React, { HTMLProps } from 'react';
import { styled, makeStyles, createStyles } from '@material-ui/core';
import blueGrey from '@material-ui/core/colors/blueGrey';
import { themeColors } from 'app/theme';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      margin: theme.spacing(2, 0),
      padding: 0,
      fontSize: '1.2rem',
      '& > li': {
        fontFamily: "'Lora', serif",
        listStyle: 'disc',
        marginLeft: theme.spacing(2),
      },
    },
    default: {},
    numeric: {
      '& > li': {
        listStyle: 'decimal',
      },
    },
    tips: {
      '& > li': {
        ...theme.typography.body1,
      },
    },
    box: {
      '& > li': {
        listStyle: 'none',
        margin: theme.spacing(0, 0, 2, 0),
        padding: theme.spacing(2),
        backgroundColor: blueGrey[50],
      },
    },
    stripe: {
      '& > li': {
        position: 'relative',
        listStyle: 'none',
        margin: theme.spacing(0, 0, 2, 0),
        padding: theme.spacing(2, 2, 2, 4),
        backgroundColor: blueGrey[50],
        '&::before': {
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          content: '" "',
          width: 12,
          backgroundColor: themeColors.powerBlue,
        },
      },
    },
    simple: {
      '& > li': {
        margin: theme.spacing(0, 0, 2, 0),
        listStyle: 'none',
      },
    },
  }),
);

export type ListProps = {
  variant?: 'default' | 'tips' | 'box' | 'simple' | 'numeric' | 'stripe';
} & HTMLProps<HTMLUListElement>;

export function List(props: ListProps) {
  const classes = useStyles();
  const variant = props.variant || 'default';
  const className = [classes.root, classes[variant as keyof typeof classes]].join(' ');
  return <ul className={className}>{props.children}</ul>;
}

export const ListItem = styled('li')(({ theme }) => ({
  lineHeight: 1.5,
  marginBottom: theme.spacing(2),
}));
