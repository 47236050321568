import React from 'react';
import { useScrollTrigger } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import NavPage, { ToggleDrawerButton } from 'components/NavPage';
import AppBar, { AppBarProps } from 'components/AppBar';
import Footer from './Footer';
import { AppDrawer } from './AppDrawer';
import AppBarActions from './AppBarActions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    },
    main: {
      flex: 1,
    },
    gutter: {
      ...theme.mixins.toolbar,
    },
    topics: {},
  }),
);

export type AppPageProps = {
  title: string;
  children: React.ReactNode;
  responsive?: boolean;
  fixed?: boolean;
  gutter?: boolean;
};

export function AppPage(props: AppPageProps) {
  const { title, children, responsive, fixed, gutter } = props;
  const classes = useStyles();
  const [elm, setElm] = React.useState<Node>();
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 100, target: elm });

  const appBarProps: Partial<AppBarProps> =
    trigger || Boolean(fixed)
      ? {
          color: 'primary',
          elevation: 1,
          title,
        }
      : {
          color: 'transparent',
          elevation: 0,
        };

  return (
    <NavPage drawerContent={<AppDrawer />} responsive={responsive}>
      <AppBar responsive={responsive} menu={<ToggleDrawerButton />} actions={<AppBarActions />} {...appBarProps} />
      <div className={classes.content} ref={e => setElm(e as Node)}>
        <main className={classes.main}>
          {gutter && <div className={classes.gutter} />}
          {children}
        </main>
        <Footer />
      </div>
    </NavPage>
  );
}
