import { Lesson } from 'contents/types';
import React from 'react';

export default [
  {
    id: 'calming_corner',
    title: 'The Calming Corner',
    content: React.lazy(() => import('./l1')),
  },
  {
    id: 'active_space',
    title: 'The Active Space',
    content: React.lazy(() => import('./l2')),
  },
  {
    id: 'sensory_furniture',
    title: 'Sensory Furniture',
    content: React.lazy(() => import('./l3')),
  },
  {
    id: 'flexible_learning',
    title: 'Flexible Play and Learning Spaces',
    content: React.lazy(() => import('./l4')),
  },
  {
    id: 'visual_timetables',
    title: 'Visual Timetables',
    content: React.lazy(() => import('./l5')),
  },
  {
    id: 'smooth_transitions',
    title: 'Smooth Transitions',
    content: React.lazy(() => import('./l6')),
  },
  {
    id: 'feelings_themometer',
    title: 'Feelings Thermometer',
    content: React.lazy(() => import('./l7')),
  },
  {
    id: 'visual_cards',
    title: 'Visual Cards',
    content: React.lazy(() => import('./l8')),
  },
  {
    id: 'sensory_toys',
    title: 'Sensory Toys and Tools',
    content: React.lazy(() => import('./l9')),
  },
  {
    id: 'expressive_toys',
    title: 'Expressive Toys',
    content: React.lazy(() => import('./l10')),
  },
  {
    id: 'positive_reward_system',
    title: 'Positive Reward System',
    content: React.lazy(() => import('./l11')),
  },

] as Lesson[];
