import React from 'react';
import { Typography, Grid, Container, Divider, Tooltip, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Link from 'components/Link';
import ExtLink from 'components/ExtLink';
import { TermsOfUse, Accessibility, Glossary } from './FooterContents';

const logos = [
  {
    src: '/images/logos/bestchance-logo.png',
    href: 'https://www.bestchance.org.au',
    alt: 'bestchance Child Family Care',
  },
  {
    src: '/images/logos/monash_logo_invert_052020.png',
    href: 'https://monash.edu',
    alt: 'Monash University',
  },
  {
    src: '/images/logos/mchri_logo.png',
    href: 'https://www.monash.edu/medicine/sphpm/mchri',
    alt: 'MCHRI',
  },
];

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      position: 'relative',
      clear: 'both',
      padding: theme.spacing(5, 0),
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.dark,
    },

    imageItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },

    hr: {
      margin: theme.spacing(4, 0),
      backgroundColor: theme.palette.primary.light,
      opacity: 0.3,
    },

    quickLinks: {
      display: 'flex',
      flexDirection: 'column',
      '& > ul': {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        '& > li': {
          lineHeight: 2.5,
          display: 'block',
          '& > a:hover': {
            textDecoration: 'none',
            color: theme.palette.primary.light,
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        '& > ul > li': {
          textAlign: 'center',
        },
      },
    },
  }),
);

type PopupContent = 'terms' | 'accessibility' | 'glossary' | 'partners';

export default function Footer() {
  const classes = useStyles();
  const [showContent, setShowContent] = React.useState<PopupContent | null>(null);

  const handlePopup = (content: PopupContent) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowContent(content);
  };

  return (
    <footer className={classes.root}>
      <Container>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={4} className={classes.imageItem}>
          </Grid>
          <Grid item xs={12} md={4} className={classes.quickLinks}>
            <Typography variant="h6">Quick Links</Typography>
            <ul>
              <li>
                <Link to="/journey">Journey</Link>
              </li>

              <li>
                <Link to="/team">Team</Link>
              </li>
              <li>
                <Link to="/glossary" onClick={handlePopup('glossary')}>
                  Glossary
                </Link>
              </li>
              <li>
                <ExtLink href="mailto:cheshireseed@bestchance.org.au?subject=CheshireSEED%20Feedback">
                  Contact us
                </ExtLink>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={4} className={classes.quickLinks}>
            <Typography variant="h6">About</Typography>
            <ul>
              <li>
                <Link to="/research">Relevant Research</Link>
              </li>
              <li>
                <Link to="/terms" onClick={handlePopup('terms')}>
                  Terms of use
                </Link>
              </li>
              <li>
                <Link to="/accessibility" onClick={handlePopup('accessibility')}>
                  Accessibility
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Divider className={classes.hr} />

        <Grid container justify="center" alignItems="center">
          {logos.map(item => (
            <Grid item key={item.href} xs={12} sm={4} md={3}>
              <ExtLink href={item.href}>
                <Tooltip title={item.alt}>
                  <img src={item.src} alt={item.alt} width={150} />
                </Tooltip>
              </ExtLink>
            </Grid>
          ))}
        </Grid>

        <Box my={4} textAlign="center">
          <Typography variant="caption" paragraph>
            <ExtLink href="https://www.freepik.com/free-photos-vectors/menu">
              Vector graphics created by freepik - www.freepik.com
            </ExtLink>
          </Typography>
          <Typography variant="caption">© bestchance Child Family Care 2020</Typography>
        </Box>
        <TermsOfUse open={showContent === 'terms'} onClose={() => setShowContent(null)} />
        <Accessibility open={showContent === 'accessibility'} onClose={() => setShowContent(null)} />
        <Glossary open={showContent === 'glossary'} onClose={() => setShowContent(null)} />
      </Container>
    </footer>
  );
}
