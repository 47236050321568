import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { styled, StyledProps } from '@material-ui/core/styles';

export type StatusViewProps = {
  label?: React.ReactNode;
  avatar?: React.ReactNode;
};

export default styled((props: StyledProps & StatusViewProps) => {
  const { label, avatar } = props;
  return (
    <div className={props.className}>
      {avatar && <Box my={2}>{avatar}</Box>}
      {label && (
        <Typography variant="h5" color="textSecondary">
          {label}
        </Typography>
      )}
    </div>
  );
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  padding: theme.spacing(4),
}));
