import React from 'react';
import { AppPage } from 'app/shared';
import Header from './Header';
import Intro from './Intro';
import Quote from './Quote';
import Topics from './Topics';

export default function Home() {
  return (
    <AppPage title="Cheshire SEED" responsive={false}>
      <Header />
      <Intro />
      <Quote />
      <Topics />
    </AppPage>
  );
}
