import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CardHeader, Card, TextField, Box, CardContent } from '@material-ui/core';
import Button from 'components/ActionStatusButton';
import Link from 'components/Link';
import { Form, mustBeEmail } from 'components/form';
import AuthPage from './AuthPage';

import { selectAuth } from 'app/store';
import { sendPasswordResetEmail } from './authSlice';
import { useHistory } from 'react-router-dom';

type ResetPasswordFormData = {
  email: string;
};

const initialData = {
  email: '',
};

const validators = {
  email: mustBeEmail(),
};

export default function ResetPassword() {
  const { status } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (data: ResetPasswordFormData) => {
    dispatch(
      sendPasswordResetEmail(data.email, () => {
        history.push('/');
      }),
    );
  };

  return (
    <AuthPage>
      <Card>
        <CardHeader title="Reset your password" />
        <CardContent>
          <Form<ResetPasswordFormData> data={initialData} validators={validators} onSubmit={handleSubmit}>
            {({ values, errors, updateField }) => (
              <>
                <TextField
                  variant="outlined"
                  name="email"
                  label="Your email"
                  value={values.email}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  onChange={e => updateField('email', e.target.value)}
                  margin="normal"
                  fullWidth
                />
                <Box my={2}>
                  <Button type="submit" variant="contained" color="primary" status={status} fullWidth>
                    Send reset password email
                  </Button>
                </Box>
                <Box>
                  <Link to="/auth/signin">Signin</Link>
                </Box>
              </>
            )}
          </Form>
        </CardContent>
      </Card>
    </AuthPage>
  );
}
