import { Typography, styled } from '@material-ui/core';

export const Paragraph = styled(Typography)({
  fontFamily: 'Lora, serif',
  fontSize: 21,
  lineHeight: 1.8,
});

export const H4 = styled(Typography)(props => ({
  fontWeight: 300,
  fontSize: 24,
  color: 'inherit',
  margin: props.theme.spacing(4, 0),
}));

export * from './AppPage';
export * from './ContentPage';
