import React from 'react';
import MuiRadioGroup, { RadioGroupProps as MuiRadioGroupProps } from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';

type OptionType = {
  label: string;
  value: string | number;
};

export type RadioGroupProps = MuiRadioGroupProps & {
  label?: React.ReactNode;
  options: Array<string | OptionType>;
  error?: boolean;
  helperText?: React.ReactNode;
};

export function RadioGroup(props: RadioGroupProps) {
  const { label, options, error, helperText, ...others } = props;
  return (
    <React.Fragment>
      {label && <FormLabel>{label}</FormLabel>}
      <MuiRadioGroup {...others}>
        {options.map((option: string | OptionType, index: number) => {
          const { label, value } = typeof option === 'string' ? { label: option, value: option } : option;
          return (
            <FormControlLabel
              key={index}
              value={value}
              label={label}
              control={<Radio value={value} color="primary" />}
            />
          );
        })}
      </MuiRadioGroup>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </React.Fragment>
  );
}
