import React from 'react';
import { Card, Typography, WithStyles, withStyles, Theme, createStyles } from '@material-ui/core';

export type CardProps = {
  avatar?: React.ReactNode;
  title: string;
  children: React.ReactNode;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },

    header: {
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      color: '#999',
    },

    avatar: {
      marginRight: '0.5rem',
      '& > *': {
        display: 'block',
      },
    },
  });

export default withStyles(styles)((props: WithStyles<typeof styles> & CardProps) => {
  const { classes, avatar, title, children } = props;
  return (
    <Card className={classes.root}>
      <header className={classes.header}>
        {avatar && <div className={classes.avatar}>{avatar}</div>}
        <Typography variant="subtitle2">{title}</Typography>
      </header>
      {children}
    </Card>
  );
});
