import React from 'react';
import { Topic } from 'contents/types';
import deepPurple from '@material-ui/core/colors/deepPurple';
import cover from './assets/cover.png';

export default {
  id: 'anxiety',
  entry: 'overview',
  title: '9. Anxiety in Early Childhood ',
  description:
    'We explore what anxiety might look and feel like for young children, and suggest strategies that educators can use with children to build self-esteem and resilience.',
  cover,
  themeColor: deepPurple[300],
  lessons: [
    {
      id: 'overview',
      title: '9.0 Overview and Learning Outcomes',
      content: React.lazy(() => import('./l0')),
    },
    {
      id: 'understanding',
      title: '9.1 Understanding Anxiety in Early Childhood',
      content: React.lazy(() => import('./l1')),
    },
    {
      id: 'inside-outside',
      title: '9.2 Anxiety on the Inside, Anxiety on the Outside',
      content: React.lazy(() => import('./l2')),
    },
    {
      id: 'strategies',
      title: '9.3 Strategies to Support Children Experiencing Anxiety',
      content: React.lazy(() => import('./l3')),
    },
    {
      id: 'resources',
      title: '9.4 Resources for Parents and Caregivers',
      content: React.lazy(() => import('./l4')),
    },
  ],
} as Topic;
