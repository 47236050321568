import React from 'react';
import { Lesson } from 'contents/types';

export default [
  {
    id: 'build_relationships_on_arrival',
    title: 'Build Relationships on Arrival',
    content: React.lazy(() => import('./build_relationships_on_arrival')),
  },
  {
    id: 'encourage_play',
    title: 'Encourage Play',
    content: React.lazy(() => import('./encourage_play')),
  },
  {
    id: 'expand_social_skills',
    title: 'Special Interests to Expand Social Skills',
    content: React.lazy(() => import('./expand_social_skills')),
  },

  {
    id: 'build_prosocial_skills',
    title: 'Build Prosocial Skills',
    content: React.lazy(() => import('./build_prosocial_skills')),
  },
  {
    id: 'use_visial_support',
    title: 'Use Visual Supports',
    content: React.lazy(() => import('./use_visial_support')),
  },
  {
    id: 'transition_between_activities',
    title: 'Transition between Activities',
    content: React.lazy(() => import('./transition_between_activities')),
  },

  {
    id: 'peer_modelling',
    title: 'Peer Modelling',
    content: React.lazy(() => import('./peer_modelling')),
  },
  {
    id: 'setting_up_your_environment',
    title: 'Setting Up Your Environment',
    content: React.lazy(() => import('./setting_up_your_environment')),
  },
  {
    id: 'reduce_challenging_behaviour',
    title: 'Special Interests to Reduce Challenging Behaviour',
    content: React.lazy(() => import('./reduce_challenging_behaviour')),
  },
  {
    id: 'stimming',
    title: 'Stimming',
    content: React.lazy(() => import('./stimming')),
  },
  {
    id: 'interoception',
    title: 'Interoception',
    content: React.lazy(() => import('./interoception')),
  },
] as Lesson[];
