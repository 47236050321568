import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';

export type LinkItemProps = {
  to: string;
  state?: object;
  exact?: boolean;
} & Pick<ListItemProps, 'children' | 'className' | 'classes'>;

export default function LinkItem(props: LinkItemProps) {
  const { to, state, exact, ...listItemProps } = props;
  const history = useHistory();
  const match = useRouteMatch({ path: to, exact });

  return (
    <ListItem
      {...listItemProps}
      href={to}
      onClick={() => {
        history.push(to, state);
      }}
      selected={Boolean(match)}
      button
    />
  );
}
