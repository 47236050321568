import React from 'react';
import { Topic } from 'contents';
import { LessonState } from 'app/types';
import { topics } from 'contents';
import { useSelector } from 'react-redux';
import { selectProfile } from 'app/store';

const topicPoints = topics.reduce<Record<string, Record<string, number>>>((topicPoints, topic) => {
  return {
    ...topicPoints,
    [topic.id]: topic.lessons.reduce<Record<string, number>>((lessonPoints, lesson) => {
      return {
        ...lessonPoints,
        [lesson.id]: 1,
      };
    }, {}),
  };
}, {});

export function getTopicProgress(topic: Topic, lessons: Record<string, LessonState>) {
  const lessonPoints = topicPoints[topic.id] || {};
  const visited = Object.entries(lessons).reduce<number>((count, [key, lessonState]) => {
    const [topicId, lessonId] = key.split('#');
    return count + (topicId === topic.id && lessonState._visited ? lessonPoints[lessonId] || 0 : 0);
  }, 0);
  return visited / topic.lessons.length;
}

export function useProgresses() {
  const { root: profile } = useSelector(selectProfile);
  return React.useMemo(() => {
    return topics.reduce<Record<string, number>>((progresses, topic) => {
      return { ...progresses, [topic.id]: getTopicProgress(topic, profile?.lessons || {}) };
    }, {});
  }, [profile]);
}
