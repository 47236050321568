import React from 'react';
import { makeStyles, createStyles, Avatar, Typography } from '@material-ui/core';

import DashboardCard from 'components/DashboardCard';
import InfoList from 'components/InfoList';
import PersonIcon from '@material-ui/icons/Person';
import { useSelector } from 'react-redux';
import { selectAuth, selectProfile } from 'app/store';
import { getAvatarUrl } from 'helpers';
import moment from 'moment';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(1),
    },
    avatar: {
      width: 72,
      height: 72,
      boxShadow: theme.shadows[1],
      marginBottom: theme.spacing(2),
    },
  }),
);

export default function ProfileCard() {
  const classes = useStyles();
  const { userInfo } = useSelector(selectAuth);
  const { root: profile } = useSelector(selectProfile);

  const expiry = profile?._expiry;
  const infoItems = [
    {
      label: 'Organization',
      value: profile?.organization || '-',
    },
    {
      label: 'Subscription',
      value: profile?._subscription || '-',
    },
    {
      label: 'Expiry',
      value: expiry ? moment(expiry.toDate()).format('DD/MM/YYYY') : '-',
    },
  ];
  return (
    <DashboardCard avatar={<PersonIcon />} title="My Profile">
      {userInfo && (
        <div className={classes.root}>
          <Avatar className={classes.avatar} src={getAvatarUrl(userInfo.email || '', userInfo.displayName || 'User')} />
          <Typography variant="h5" gutterBottom>
            {userInfo.displayName}
          </Typography>
          <Typography color="textSecondary">{userInfo.email}</Typography>

          <InfoList items={infoItems} />
        </div>
      )}
    </DashboardCard>
  );
}
