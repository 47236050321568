import React from 'react';
import firebase from 'firebase';
import { useHistory } from 'react-router-dom';

export function useScreenView(app_name: string) {
  const { listen } = useHistory();
  React.useEffect(
    () =>
      listen(location => {
        firebase.analytics().logEvent('screen_view', {
          app_name: 'cheshire-seed',
          screen_name: location.pathname,
        });
      }),
    [listen],
  );
}
