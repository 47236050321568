import React from 'react';
import useContent from 'components/lesson/useContent';
import { StepFormData } from 'components/StepFrom';
import EnvironmentWizardBase from './EnvironmentWizard';

export * from './EnvironmentCard';

export const EnvironmentWizard = () => {
  const [content, setContent] = useContent('environment_wizard');
  const { activeStep = 0, ...values } = content || {};
  const data: StepFormData = { activeStep, values: values || {} };
  const setData = (data: StepFormData) => {
    setContent({ ...data.values, activeStep: data.activeStep });
  };
  return <EnvironmentWizardBase data={data} setData={setData} />;
};
