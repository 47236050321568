import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { VideoEvent, VideoEventStatus } from 'app/types';
import { selectVideo } from 'app/store';
import { ActionStatus } from 'app/helper';
import { loadVideos } from './videoSlice';

export default function useVideos(
  videoStatus: VideoEventStatus,
): { videos: VideoEvent[] | null; status: ActionStatus | null } {
  const { videos: allVideos, status } = useSelector(selectVideo);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!videos) {
      dispatch(loadVideos());
    }
    // eslint-disable-next-line
  }, []);

  const videos = React.useMemo(() => {
    if (!allVideos) {
      return null;
    }
    return allVideos.filter(video => video.status === videoStatus);
  }, [allVideos, videoStatus]);

  return {
    videos,
    status,
  };
}
