import React from 'react';
import { Topic } from 'contents/types';
import indigo from '@material-ui/core/colors/indigo';

export default {
  id: 'my_environment',
  entry: 'overview',
  title: '3. My Environment',
  description:
    'Learn about ways to structure your room for maximum social and emotional learning, and to benefit children experiencing specific behavioural or emotional challenges.',
  cover: '/assets/m3/cover.jpg',
  themeColor: indigo[300],
  lessons: [
    {
      id: 'overview',
      title: '3.0 Overview and Learning Outcomes',
      content: React.lazy(() => import('./l0')),
    },
    {
      id: 'sensory_processing',
      title: '3.1 Sensory Processing in Early Childhood',
      content: React.lazy(() => import('./l1')),
    },
    {
      id: 'strategies',
      title: '3.2 "My Environment" Strategies',
      content: React.lazy(() => import('./l2')),
    },
    {
      id: 'checking_in',
      title: '3.3 Checking-In',
      content: React.lazy(() => import('./l3')),
    },
  ],
} as Topic;
