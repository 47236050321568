import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { LocationDescriptorObject } from 'history';
import { selectAuth } from 'app/store';

export type ContinueState = {
  continueUrl?: string;
};

export default function withAuth<T>(Comp: React.ComponentType<T>): React.ComponentType<T> {
  return (props: T) => {
    const { authenticated } = useSelector(selectAuth);
    const location = useLocation();

    // wait for auth to have result
    if (authenticated === null) {
      return null;
    }

    // ask signin if not authenticated
    if (!authenticated) {
      const nextLocation: LocationDescriptorObject<ContinueState> = {
        pathname: '/auth/signin',
        state: { continueUrl: location.pathname },
      };
      return <Redirect to={nextLocation} />;
    }

    return <Comp {...props} />;
  };
}
